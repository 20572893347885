.fundsMain {
    display: flex;
    flex-direction: column;
    gap:15px;
    padding:20px;
    margin-top:5px;
}

.fundsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 12px;
    padding:10px;
    box-shadow: 0px 0px 10px 0px black;
}

.fundsHeader {
    color:rgb(112, 122, 131);;
    font-size:20px;
}

.funds {
    color:black;
    font-size:25px;
    font-weight:600;
}

.MATICHeader {
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.MATICHeader img {
    width:25px;
    margin-left:8px;
}