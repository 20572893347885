/*CREATE*/

.createMain {
  background-color:#1a1a1a;
  /* background-image: url(https://cineteria.com/wp-content/uploads/2021/04/cineteria-background-tile.png); */
  padding: 14px 14px 14px 14px;
}

.NFTForm {
  background-color: #FFFFFF;
  border-width: 5px 5px 5px 5px;
  box-shadow: 0px 0px 10px 2px rgb(0 0 0 / 50%);
  border-radius: 15px;
  padding:20px;
}

@media(hover: hover) and (pointer: fine) {
  .previewimg:hover {
    border: 5px solid black !important;
    width: 420px;
    cursor:pointer;
  }
}

@media only screen and (max-width:716px) {
    body .submitbttncont {
        display:flex;
        justify-content:center;
    }
  
    body .progresscont {
        display:flex;
        justify-content:center;
        margin-top:15px;
    }
  
    body .progressdiv {
        margin-left: 0px;
        max-width: 500px;
        width: 100%;
        display: flex;
        justify-content: center;
    }
  
    body .connectwalletcont {
        display:flex;
        justify-content: center;
        margin-top: 10px;
    }
    body #submit-group {
        text-align:center;
    }
  }
  
  .NFTForm .info {
    color:gray;
    font-size:13px !important;
    margin-bottom:10px;
  }

  .error {
  color:red;
  font-size:15px;
  }
  
  .previewimg {
    width:320px;
    border: 3px dashed #555 !important;
    border-radius: 10px !important;
    transition: border 0.2s ease-in-out, width 0.2s ease-in-out;
  }
  
  .imagelabel {
    display: inline-flex;
    margin-bottom: 20px;
  }
  
  input#showAudios + label {
    display:inline;
  }
  
  .desc {
    min-width: 100%;
    min-height: 150px;
    line-height: 1.5;
    border: 1px solid #ddd;
    width: 100%;
    font-size: 14px;
    padding: 6px 12px;
    vertical-align: middle;
    color: #333;
    outline:0;
  }
  
  .numberbox {
    max-width:150px !important;
  }
  
  .createMain .submitbttn {
    background-color: #00ffcd !important;
    color: black !important;
    font-weight: 700 !important;
    padding: 18px 25px !important;
    font-size: 20px !important;
    border-radius: 12px;
    border: 0;
    text-align: center;
    letter-spacing: 0.1em;
    line-height: 1;
    text-transform: uppercase;
    margin: 0;
    transition: all 0.3s ease;
  }
  
  .createMain .submitbttn:hover {
    transform:scale(1.1);
    color:white !important;
    transition: all 0.2s ease-in-out;
  }
  
  .createMain .submitbttn:disabled {
    background-color:#00ffcd !important;
    color:grey !important;
    font-weight:700 !important;
    padding: 18px 25px !important;
    font-size:15px !important;
    transition: all 0.5s ease-in-out;
  }
  
  
  /*For custom select */
  .custom-select {
  position: relative;
  font-family: Arial;
  width:200px;
  }
  
  .custom-select select {
  display: none; /*hide original SELECT element: */
  }
  
  .select-selected {
  background-color: white;;
  }
  
  /* Style the arrow inside the select element: */
  .select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: grey transparent transparent transparent;
  border-radius:10px;
  }
  
  /* Point the arrow upwards when the select box is open (active): */
  .select-selected.select-arrow-active:after {
  border-color: transparent transparent grey transparent;
  top: 7px;
  }
  
  /* style the items (options), including the selected item: */
  .select-items div {
  color: black;
  padding: 8px 16px;
  border: 1px solid grey;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1);
  border-radius:10px;
  background-color: white;
  cursor: pointer;
  transition: all 200ms ease-in-out;
  display:flex;
  align-items:center;
  }
  
  .select-items i {
    color: black;
    font-size: 20px;
    margin-left: 5px;
  }
  
  .select-selected {
    color: black;
    padding: 8px 16px;
    border: 1px solid grey;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    cursor: pointer;
    font-size: 15px;
  }
  
  /* Style items (options): */
  .select-items {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 99;
    border-radius: 10px;
    filter: drop-shadow(5px 5px 4px grey);
    font-size: 14px;
  }
  
  /* Hide the items when the select box is closed: */
  .select-hide {
  display: none;
  }
  
  .select-items div:hover, .same-as-selected {
  background-color: #E8E8E8
  }
  
  .tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
  }
  
  .tooltip .tooltiptext {
  visibility: hidden;
  width: 600px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: -5px;
  left: 105%;
  }
  
  .tooltip:hover .tooltiptext {
  visibility: visible;
  }
  
  .createMain .displayflex {
    display:flex;
    align-items:center;
  }
  
  .percentagecontainer {
    display:flex;
    justify-content: center;
    align-self:center;
    font-size:20px;
    margin-left: 3px;
    font-weight: 600
  }
  
  .progressdiv {
    display:inline-block;
    margin-left:20px;
    width:500px;
    height:20px;
    background-color:black;
    opacity:0;
    transition: 1s;
  }
  
  .progressdiv.show {
    opacity:1;
    
  }
  
  .progressbar {
    width:0%;
    height:20px;
    background-color:#00ffcd;
    background: #00ffcd; /* Old browsers */
  background: #00ffcd; /* Old browsers */
  background: -moz-linear-gradient(top, #00ffcd 0%, #4cffd5 50%, #51ffd6 51%, #84ffe2 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #00ffcd 0%,#4cffd5 50%,#51ffd6 51%,#84ffe2 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #00ffcd 0%,#4cffd5 50%,#51ffd6 51%,#84ffe2 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    opacity:1;
    align-content:center;
    white-space:nowrap;
    text-align:center;
    color:white;
    font-weight:500;
    line-height: 20px;
    transition: 1s;
  }
  
  .progressbar.show {
    opacity:1;
    
  }
  
  .connectingwallet {
    display:inline-block;
    margin-left:20px;
    color:black;
    font-size:17px;
    transform:scale(0);
    opacity:0;
    transition: 1.5s;
  }
  
  .connectingwallet.show {
    z-index:10;
    transform:scale(1);
    opacity:1;
  }
  
  .connectingwalletimg {
    height:36px;
    width:36px;
    margin-left:8px;
  }
  
  .chaintitle {
    font-size: 20px;
  }
  
  .createMain .ethchainimg {
    width: 15px;
    margin-left: 5px;
  }
  
  .createMain .chainimg {
    width: 25px;
    margin-left: 5px;
  }
  
  #nftform input[type="text"], #nftform input[type="number"], #nftform textarea {
    border-radius: 10px !important;
    background-color: white !important;
    transition: all 0.3s ease;
  }
  
  #nftform input[type="text"]:hover, #nftform input[type="number"]:hover, #nftform textarea:hover {
    box-shadow: 0 0 15px 4px rgba(0,0,0,0.06);
  }
  
  .audioList {
    background-color: white;
    box-shadow: 0 0 15px 4px rgba(0,0,0,0.06);
    padding: 10px;
    margin-bottom: 10px;
    transition: all 200ms ease-in-out;
  }
  
  .audioList:hover {
    box-shadow: 0 0 15px 4px rgba(0,0,0,0.10);
  }
  
  .audioList ul {
    margin: 0 !important;
  }
  
  .audioList li {
    font-size: 20px;
    padding: 10px;
    border-bottom: 2px rgba(0,0,0,0.06) solid;
    color: black;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .audioList li i {
    font-size: 25px !important;
    margin-left: 3px;
    border-radius: 7px;
    box-shadow: 0px 1px 3px;
    padding: 2px;
  }
  
  .audioList label {
    margin-bottom: 0px !important;
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
  }
  
  .audioList i {
    font-size:35px !important;
    cursor:pointer;
  }
  
  .audioListadd {
    display: flex;
    cursor: pointer;
    text-align: center;
    justify-content: center;
  }
  
  .chainselect {
    box-shadow: 0 0 15px 4px rgba(0,0,0,0.06);
    border-radius:10px;
    padding:10px;
    cursor:pointer;
    margin-bottom:5px;
    transition:all 200ms ease-in-out;
  }
  
  .chainselect:hover {
    box-shadow: 0 0 15px 4px rgba(0,0,0,0.10);
  }
  
  input:checked + label div {
    background-color:#1a1a1a;
    color:white;
  }
  
  .chaininput {
    margin-right: 10px;
    display: none !important;
  }
  
  .submitbttncont {
    display: inline;
  }
  
  .progresscont {
    display: inline-block;
  }
  
  .connectwalletcont {
    display: inline-block;
  }
  
  .switchMATICbttn {
    background: #8247e5;
    color: white;
    padding: 16px;
    font-size: 18px;
    line-height: 18px;
    border-radius: 10px;
    box-shadow: 0px 0px 2px black;
    margin-bottom: 30px;
    transition: 200ms 0.1s;
  }
  
  .switchETHbttn {
    background: #00ffcd;
    color: black;
    padding: 16px;
    font-size: 18px;
    line-height: 18px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px black;
    margin-bottom: 30px;
    transition: 200ms 0.1s;
  }
  
  .switchMATICbttn:active {
    transform: scale(1.05);
    transition: 100ms;
  }
  
  .switchETHbttn:active {
    transform: scale(1.05);
    transition: 100ms;
  }
  
  .albumtype {
    display:none;
  }
  
  .albumtype.show {
    display:block;
    animation: fadeIn 0.5s;
  }
  
  .requiredicon {
    color:red;display:inline
  }
  
  .optionaltext {
    color:grey;
    display:inline;
    font-size:14px
  }
  
  .tips {
    color:black;
    display:inline;
    font-size:14px;
  }
  
  .darkgrey {
    color:darkgrey;
  }
  
  .displayNone {
    display:none;
  }
  
  .displayInline {
    display:inline;
  }
  
  .polygonLogo {
    width:30px;
    margin-left:9px;
  }
  
  .ethLogo {
    width:20px;
    margin-left:5px;
  }
  
  .submitError {
    margin-top: 10px;
  }
  
  @keyframes fadeIn {
    from {
        opacity: 0;
    }
  
    to {
        opacity: 1;
    }
  }

  .showAudiosTip {
    color:black;
    display:inline;
    font-size:14px;
  }

  .showAudiosCheck {
    margin-right:10px;
    background-color: #fff;
    border: 1px solid #bbb;
    line-height: 0;
    width: 16px;
    min-width: 16px;
    height: 16px;
    outline: 0;
    text-align: center;
    vertical-align: middle;
    clear: none;
    cursor: pointer;
  }
  
  .NFTForm input[type="text"] {
    display: inline-block;
    min-height: 40px;
    width: 100%;
    font-size: 14px;
    line-height: 1.8;
    padding: 6px 12px;
    vertical-align: middle;
    color: #333;
    border: 1px solid #ddd;
    outline: 0;
  }

  .NFTForm input[type="number"] {
    width: 100%;
    font-size: 14px;
    line-height: 1.8;
    outline: 0;
    color: #333;
    border: 1px solid #ddd;
    min-height:36px;
    padding-left: 8px;
  }

  .NFTForm .royalty {
    max-width: 58px;
  }

  .NFTForm input[type="checkbox"] {
    margin-right:10px;
    background-color: #fff;
    border: 1px solid #bbb;
    line-height: 0;
    width: 16px;
    min-width: 16px;
    height: 16px;
    outline: 0;
    text-align: center;
    vertical-align: middle;
    clear: none;
    cursor: pointer;
  }

  .createMain .popup .creatingProgress {
    font-size:18px !important;
    font-weight:700;
  }