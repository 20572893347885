/*HOME CSS*/

@media only screen and (max-width: 1200px) {

    .quipheader {
        font-size:53px;
    }
  
    .buttoninfo {
        width: 100% !important;
        padding: 10px;
        max-width: 609px;
        font-size: 28px !important;
    }
  
    .menubutton {
        font-size: 22px !important;
    }
  
    .maincontent {
        flex-direction: column;
        gap:0px !important;
    }
  
    .featurednftcontainer {
        margin-top: 60px !important;
    }
  
    body .categorylist {
        display: grid !important;
        place-items: center;
        grid-template-columns: repeat(4, clamp(84px, 20vw, 135px) );
        /* grid-template-columns: repeat(4, 20vw );*/
        grid-template-rows: repeat(4, 150px);
        justify-content: center !important;
    }
  
    .categoryitem {
        grid-column: span 2;
    }
  
        .categoryitem:last-child {
            grid-column: 2 / span 2;
        }
  
        .categoryitem > * {
            font-size: 2rem !important;
        }
  
    .listexamples {
        padding: 10px;
    }
  }
  
  selector:before {
    backdrop-filter: blur(10px);
  }
  
  
  .homeMain h3 {
    text-align: center;
    color: white;
    font-weight: 500 !important;
    filter: drop-shadow(0px 5px 1px black);
    font-size: 45px !important;
    padding: 10px;
    border-radius: 20px;
  }
  
  .homeMain h4 {
    color: white;
    font-size: 40px;
    text-align: center;
    margin: 10px;
    padding: 10px;
    margin-bottom: 40px;
  }
  
  .homeMain h5 {
    color: white;
    font-size: 40px;
    text-align: center;
    margin: 10px;
    padding: 10px;
  }
  
  .homeMain p {
    color: white;
    font-size: 60px !important;
  }
  
  .homeMain i {
    color: white;
  }
  
  .homeMain a {
    color: inherit;
  }
  
    .homeMain a:hover {
        color: inherit;
    }
  
  .homeMain ul {
    color: white;
    font-size: 25px;
    margin: 15px 0 15px 20px !important;
    padding-left: 2rem !important;
  }
  
  li {
  }
  
  .homeMain {
    background: #00ffcd;
    background: rgb(0,255,205);
    background: radial-gradient(circle, rgba(0,255,205,1) 0%, rgba(0,255,205,1) 57%, rgba(0,185,149,1) 100%);
    padding-top:15px;
  }
  
  .maincontent {
    align-items: center;
    margin-bottom:40px;
    gap:169px;
  }
  
  .secondarycontent {
    background: #1a1a1a;
  }
  
  .quipheader {
    color: #A39EFF;
    font-weight: 500;
    font-size: 50px;
    filter: drop-shadow(2px 2px 0px #1a1a1a); /*1*/
    text-align: center;
  }
  
  
  .buttoninfo {
    color: white;
    font-size: 30px;
    /* width: 60%; */
    text-align: center;
    filter: drop-shadow(2px 1px 2px #1a1a1a);
  }
  
  .menubuttoncontainer {
    align-items: center;
  }
  
  body .menubutton {
    background-color: white;
    color:black;
    border: 2px black solid;
    border-radius: 15px;
    padding: 15px;
    font-size: 25px;
    font-weight: 500;
    margin: 10px;
    filter: drop-shadow(0px 2px 2px #1a1a1a);
    transition: all 200ms ease-in-out;
  }
  
  .menubutton.first {
      color:white;
    background-color:#1a1a1a;
  }
  
    .menubutton:hover {
        transform: scale(1.1);
        filter: drop-shadow(0px 2px 5px #1a1a1a);
    }
  
  .featurednftcontainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 543px;
  }
  
  .featuredNFT {
    display: block;
  }
  
  .assetimage {
    width:100px;
    height:100px!important;
  }
  
  .assetname {
    font-size: 12px;
  }
  
  .benefitsblock {
    margin-top: 86px;
  }
  
  .benefitscont {
    margin-bottom:30px;
  }
  
  .maxwidthBenefits {
    max-width: 913px;
  }
  
  .cineteriacolor {
    color:#00ffcd
  }
  
  .startnowCont {
    text-align:center;
    padding-bottom:50px;
  }
  
  .homeMain .assetCard {
    height: auto;
    box-shadow: 0px 4px 5px black !important;
  }
  
  .categoryitem {
    align-items: center;
  }
  
  .homeMain .listicons {
    font-size: 50px;
  }
  
  .listexamples {
    list-style: square !important;
    margin-bottom: 10px;
  }
  
  .listcomment {
    color: lightgrey;
    font-size: 20px
  }