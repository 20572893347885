.marketplacemain {
    min-height:100vh;
    padding:20px;
}

.marketplacemain .master {
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    -moz-transition: opacity 1.5s;
    -webkit-transition: opacity 1.5s;
    -o-transition: opacity 1.5s;
    padding: 3vw;
    transition: all 1.5s;
}