@media only screen and (max-width: 767px) {
    body .profilenav {
        justify-content:start;
    }

    body .attachment-medium_large size-medium_large {
        width: 8vw;
        display: flex !important;
    }
}

@media only screen and (max-width: 660px) {

    body .headerbuttonsgrid {
        height: 100px;
        background-color: #1a1a1a;
    }

    body .navresultsflex {
        flex-direction: column-reverse;
        
    }

    body .filterblock {
        position: fixed;
        top: 50vh;
        left: 50%;
        z-index: 9999;
        transform: translate(-151%, -50%) scale(1);
        transition: all 200ms ease-in-out;
    }

    body .filterblock.show {
        transform: translate(-50%, -50%) scale(1);
    }

    body .filtercontainer {
        width: 101vw;
        height: 101vh;
        /* height: calc(100vh); */
        box-shadow: 0px 0px 0px;
        border-radius: 0px;
        margin-top: 0px;
    }

    body .filterheader {
        height:75px;
    }

    body .panelheader {
        padding:10px;
        max-height: 320px;
    }

    body .mobilefilterbutton {
        position: sticky;
        background-color: white;
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
        padding: 5px;
        z-index: 3;
        margin-left: -20px;
        /* margin-top: 10px; */
        filter: drop-shadow(2px 4px 6px black);
        top: 70%;
        display: block !important;
        width: 35px;
    }

    body .filterback {
        display:flex;
    }

    body .resetfilters {
        margin-right:0px;
    }

    body .showfilter {
        position:fixed;
        left:0;
        right:0;
        overflow-y:scroll;
    }

}

.profileMain {
    background-color:#1a1a1a;
    padding-top: 10px;
}

.profileMain input {
    line-height: normal;
    -webkit-writing-mode: horizontal-tb !important;
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    -webkit-rtl-ordering: logical;
    box-sizing: border-box;
}

input[type="file" i] {
    appearance: none;
    background-color: initial;
    cursor: default;
    align-items: baseline;
    text-overflow: ellipsis;
    white-space: pre;
    text-align: start !important;
    padding: initial;
    border: initial;
    overflow: hidden !important;
    box-sizing: border-box;
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizelegibility;
    font-feature-settings: "liga" !important;
    /* font-size: 24px !important; */
    box-sizing: border-box;
}

.profilehead {
    display: block;
    margin-top:10px;
}

.profilebackground {
    opacity: 0;
    transition: all 1.5s ease-in-out;
}

.profileinfo {
    max-width: 500px;
    position: relative;
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    min-height: 200px;
    margin: 0px auto;
    box-sizing: border-box;
    transition: all 1.5s ease-in-out;
}

.imagediv {
    position: relative;
    z-index: 50;
    box-sizing: border-box;
    display: block;
    width: 104px;
    height: 104px;
}

.imageinputcontainer {
    padding: 50px; /*4*/
    cursor: pointer;
    position: absolute;
    inset: 0px;
    border: none;
    border-radius: 50%;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
    z-index: 2;
    box-sizing: border-box;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.15);
    transition:all 200ms ease-in-out;
}

.imageinputcontainer:hover {
    opacity: 1;
}

.profileimagecontainer {
    height: 100%;
    width: 100%;
    cursor: pointer;
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    max-height: 100%;
    max-width: 100%;
    overflow: hidden;
    position: relative;
    border-radius: 50%;
    border: double 4px transparent;
    border-radius: 80px;
    background-image: linear-gradient(#1a1a1a, #1a1a1a), radial-gradient(circle at top left, #FFFFFF,#FFFFFF);
    background-origin: border-box;
    background-clip: content-box, border-box;
}

.profilePic {
    object-fit: cover;
    height: 100% !important;
    transition: opacity 400ms ease 0s;
    width: 100%;
    border: 0;
    box-sizing: border-box;
}

.imageinputoverlay {
    position: absolute;
    inset: 0px;
    z-index: 70;
    opacity: 0;
    height: 0px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    display: block;
}

.imageinputoverlayflex {
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
    height: 100%;
    box-sizing:border-box;
}

.editprofileimageicon {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizelegibility;
    color: rgb(255, 255, 255);
    font-feature-settings: "liga" !important;
    font-size: 24px !important;
    box-sizing: border-box;
}

.profilenamecontainer {
    display:flex;
    justify-content:center;
    align-items:center;
}

.verificationbadge {
    margin-left: 4px;
    width:24px;
    height:24px;
}

.verificationbadge img {
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.profilename {
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    font-size: 30px;
    font-weight: 600;
    position: relative;
    box-sizing: border-box;
    color: white;
}

.profileaddresscontainer {
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    min-height: 18px;
    margin-top: 0px;
    position: relative;
    box-sizing: border-box;
    color: white;
}

.addresscopybutton {
    display: flex;
    margin-left: 8px;
    color: white;
    font-weight: 600;
    font-size: 16px;
    -webkit-box-align: center;
    align-items: center;
    font-family: inherit;
    border: 0px;
    padding: 0px;
    background: inherit;
    -webkit-appearance: button;
    cursor: pointer;
    text-transform: none;
    overflow: visible;
    font: inherit;
    margin: 0;
    box-sizing: border-box;
    -webkit-writing-mode: horizontal-tb !important;
    text-rendering: auto;
    letter-spacing: normal;
    word-spacing: normal;
    text-indent: 0px;
    text-shadow: none;
    text-align: center;
}

.addresstext {
    box-sizing: border-box;
    display: block;
    transition: all 200ms ease-in-out;
}

.profilebiodiv {
    box-sizing: border-box;
    display: block;
}

.profilebio {
    width: 500px;
    color: white;
    box-sizing: border-box;
    display: block;
    width:100%;
}

.profilebiotext {
    padding: 3px 0px 0px;
    margin: 0px;
    font-weight: 500;
    font-size: 17px;
    text-align: center;
    box-sizing: border-box;
    display: block;
}

.joindate {
    margin: 0px 0px 12px;
    font-weight: 400;
    text-align: center;
    font-size: 15px;
    color: white;
    box-sizing: border-box;
    display: block;
}

.profilenav {
    padding: 0px 8px;
    box-shadow: 0px 10px 10px -8px black;
    margin-top: 30px;
    margin: 0px !important;
    margin-bottom: 7px !important;
    display: flex;
    justify-content: center;
    overflow: auto;
    list-style-type: none;
    box-sizing: border-box;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}

.profilenavbutton {
    position: relative;
    list-style-type: none;
    border-radius: 20px;
    box-sizing: border-box;
    display: list-item;
    text-align: -webkit-match-parent;
}

.profileMain .profilenavlink {
    padding: 12px 20px;
    width: 100%;
    border-radius: 10px;
    transition: color 0.4s ease 0s, background-color 0.4s ease 0s;
    color: white;
    display: flex;
    background:none;
    box-sizing: border-box;
    cursor: pointer;
}

.profileMain .profilenavlink.active {
    color: #00ffcd;
}

    .profilenavlink:hover {
        color: #00ffcd;
    }

.profilenavicon {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
    align-self: center;
    order: 2;
    text-rendering: optimizelegibility;
    font-feature-settings: "liga" !important;
    font-size: 24px !important;
    box-sizing: border-box;
}

.profilenavtext {
    margin-left: 10px;
    flex: 0 1 0%;
    text-align: left;
    margin-right: 10px;
    order: 3;
    color: inherit;
    font-weight: 600;
    font-size: 18px;
    box-sizing: border-box;
}

.profilenavcountcontainer {
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-flow: column;
    -webkit-box-pack: center;
    justify-content: center;
    max-width: 40%;
    order: 4;
    overflow: hidden;
    text-align: right;
    box-sizing: border-box;
}

.profilenavcount {
    font-weight: 500;
    font-size: 16px;
    color: inherit;
    box-sizing: border-box;
}

.navresultsblock {
    width: 100%;
    box-sizing: border-box;
    display: block;
    padding: 20px;
}

.navresultscontainer {
    box-sizing: border-box;
    display: block;
}

.navresultsflex {
    display: flex;
    width: 100%;
    box-sizing: border-box;
}

.filterblock {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    display: block;
    border: 0px solid white;
}

.filtercontainer {
    width: 340px;
    display: block;
    overflow: auto;
    box-sizing: border-box;
    z-index: 10;
    position: sticky;
    bottom: 0px;
    top: 0px;
    flex-shrink: 0;
    /*background-color: rgb(255, 255, 255);*/
    align-self: flex-start;
    height: calc(85vh);
    margin-top: 15px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 5px black;
}

.filtermain {
    height: 100%;
    display: block;
    background-color: #1a1a1a;
    position: initial;
    top: initial;
    z-index: 3;
    width: 100%;
    box-sizing: border-box;
}

.filterheader {
    height: 60px;
    cursor: pointer;
    top: 0px;
    display: flex;
    border-bottom: 1px solid black;
    background-color: white;
    -webkit-box-align: center;
    align-items: center;
    flex: 0 0 auto;
    order: 1;
    -webkit-box-flex: 0;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 16px;
    position: sticky;
    z-index: 5;
    box-sizing: border-box;
}

.filterbutton {
    -webkit-appearance: button;
    text-transform: none;
    overflow: visible;
    color: inherit;
    font: inherit;
    margin: 0;
    box-sizing: border-box;
    -webkit-writing-mode: horizontal-tb !important;
    letter-spacing: normal;
    word-spacing: normal;
    text-indent: 0px;
    text-shadow: none;
    text-align: center;
}

.filterheadertext {
    color: black;
    font-size: 20px;
    font-weight: 600;
    display: flex;
    flex: 0 0 auto;
    order: 0;
    -webkit-box-flex: 0;
    justify-content: center;
    align-items: center;
    align-content: center;
    box-sizing: border-box;
}

.filtericon {
    margin-right: 10px;
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizelegibility;
    font-feature-settings: "liga" !important;
    font-size: 24px !important;
    box-sizing: border-box;
}

.statefiltercontainer {
    font-weight: 500;
    user-select: none;
    box-sizing: border-box;
    display: block;
}

.panelcontainer {
    box-sizing: border-box;
    display: block;
}

.paneldiv {
    border-top: none;
    border-left: none;
    border-right: none;
    margin-bottom: -1px;
    margin-top: 1px;
    border-radius: 0px;
    border-image: initial;
    color: black;
    background-color: white;
    overflow: hidden;
    box-sizing: border-box;
    display: block;
}

.panelheader {
    border: 0px;
    margin: 0px;
    border-radius: 0px;
    -webkit-box-align: center;
    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: 16px;
    font-weight: 600;
    padding: 20px;
    user-select: none;
    background-color: rgb(255, 255, 255);
    box-sizing: border-box;
}

.expandlessicon {
    margin-left: auto;
    color: black;
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizelegibility;
    font-feature-settings: "liga" !important;
    font-size: 24px !important;
    box-sizing: border-box;
}

.panelbody {
    border-left: 0px;
    border-right: 0px;
    border-radius: 0px;
    border-top: 1px solid black;
    color: white;
    background-color: #1a1a1a;
    box-sizing: border-box;
    display: block;
    max-height:0;
    overflow:hidden;
    transition: all 200ms ease-in-out;
}

.panelcontent {
    height: initial;
    overflow: visible;
    transition: height 100ms ease 0s;
    box-sizing: border-box;
    display: block;
}

.panelfilteritems {
    display: flex;
    flex-flow: wrap;
    padding: 20px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-sizing: border-box;
}

.panelpadded {
    padding: 20px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-sizing: border-box;
    display: block;
}

.panelbutton {
    -webkit-box-align: center;
    align-items: center;
    text-align: center;
    border: 1px solid rgb(229, 232, 235);
    border-radius: 10px;
    color: rgb(53, 56, 64);
    background-color: rgb(255, 255, 255);
    display: flex;
    height: 40px;
    margin: 4px;
    padding: 15px;
    width: 1000%;
    cursor: pointer;
    font-size: 100%;
    font-family: inherit;
    -webkit-appearance: button;
    text-transform: none;
    overflow: visible;
    font: inherit;
    box-sizing: border-box;
    -webkit-writing-mode: horizontal-tb !important;
    letter-spacing: normal;
    word-spacing: normal;
    text-indent: 0px;
    text-shadow: none;
    text-align: center;
}

.statusb {
    transition: all 200ms ease-in-out;
}

.statusb:hover, .statusb.active {
    transform:scale(1.1);
    background-color:#00ffcd;
    border: 1px solid #00ffcd;
}

.selectinputcontainer {
    font-size: inherit;
    line-height: inherit;
    min-height: inherit;
    cursor: pointer;
    display: flex;
    background-color: #141414;
    border-radius: 10px;
    border: 1px solid white;
    width: 100%;
    padding: 12px;
    box-sizing: border-box;
}

.currencyflex {
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
    margin-right: 12px;
    box-sizing: border-box;
}

.currencycontainer {
    overflow: hidden;
    border: 1px solid white;
    border-radius: 50%;
    padding: 4px;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
    width: 30px;
    height: 30px;
    background-color: white;
}

.currencyimg {
    object-fit: contain;
    width: 14px;
    height: 14px;
    border: 0;
    box-sizing: border-box;
}

.currencyinput {
    cursor: pointer;
    font-size: inherit;
    line-height: inherit;
    min-height: inherit;
    background-color: transparent;
    border: none;
    outline: none;
    width: 100%;
    color: inherit;
    font: inherit;
    margin: 0;
    box-sizing: border-box;
    -webkit-writing-mode: horizontal-tb !important;
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: start;
    appearance: auto;
    -webkit-rtl-ordering: logical;
    padding: 1px 2px;
}

.inputarrowflex {
    font-size: inherit;
    line-height: inherit;
    cursor: pointer;
}

.inputarrowicon {
    color: white;
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizelegibility;
    cursor: pointer;
    font-feature-settings: "liga" !important;
    font-size: 24px !important;
    box-sizing: border-box;
}

.minmaxflex {
    -webkit-box-pack: justify;
    justify-content: space-between;
    display: flex;
    margin-top: 16px;
    box-sizing: border-box;
}

.minmaxblock {
    width: calc(50% - 15px);
    box-sizing: border-box;
    display: block;
}

.minmaxsection {
    border-radius: 10px;
    border: 1px solid white;
    overflow: hidden;
    display: block;
    box-sizing: border-box;
}

.minmaxinputcontainer {
    margin-top: -1px;
    margin-bottom: -1px;
    border-radius: inherit;
    box-sizing: border-box;
    display: block;
}

.minmaxmain {
    border-bottom: 0px;
    border-radius: inherit;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    background-color: #141414;
    border: 1px solid white;
    display: flex;
    position: relative;
    box-sizing: border-box;
}

.minmaxprefix {
    -webkit-box-align: center;
    align-items: center;
    background-color: white;
    color: white;
    display: flex;
    padding-left: 12px;
    box-sizing: border-box;
}

.minmaxinput {
    background-color: white;
    border: none;
    flex: 1 0 0%;
    height: 48px;
    font-weight: 600;
    outline: none;
    padding: 0px 12px 0px 0px;
    min-width: 0px;
    line-height: normal;
    color: black;
    font: inherit;
    margin: 0;
    box-sizing: border-box;
    -webkit-writing-mode: horizontal-tb !important;
}

.toflex {
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
    padding: 0px 8px;
    box-sizing: border-box;
}

.applyblock {
    margin-top: 16px;
    width: 100%;
    box-sizing: border-box;
    display: block;
}

.applybutton {
    opacity: 1;
    display: inline-flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 10px;
    -webkit-box-pack: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    padding: 12px 20px;
    background-color: white;
    color: black;
    width: 100%;
    -webkit-appearance: button;
    text-transform: none;
    overflow: visible;
    margin: 0;
    box-sizing: border-box;
    -webkit-writing-mode: horizontal-tb !important;
    letter-spacing: normal;
    word-spacing: normal;
    text-indent: 0px;
    text-shadow: none;
    text-align: center;
    transition: all 200ms ease-in-out;
}

.applybutton:hover {
    transform:scale(1.1);
    background-color:#00ffcd;
}

.categoryfilter {
    position: relative;
    display: block;
    z-index: 4;
    width: 100%;
}

.scrollboxcontainer {
    /*max-height: 220px;*/
    overflow: auto;
    display: flex;
    flex-direction: column;
    box-sizing: bording-box;
}

.scrollboxcontent {
    border-color: white;
    overflow-y: auto;
    transition: border-color 0.2s ease-in 0s;
    box-sizing: border-box;
    display: block;
}

.categorybutton {
    -webkit-box-align: center;
    align-items: center;
    color: white;
    cursor: pointer;
    display: flex;
    height: 40px;
    padding: 0px 8px;
    font-size: 100%;
    font-family: inherit;
    border: 0px;
    background: inherit;
    -webkit-appearance: button;
    text-transform: none;
    overflow: visible;
    font: inherit;
    margin: 0;
    box-sizing: border-box;
    -webkit-writing-mode: horizontal-tb !important;
    transition: all 200ms ease-in-out !important;
    transition:all 200ms ease-in-out;
}

.categorybutton:hover, .categorybutton.active {
    color: #00ffcd;
    transform:scale(1.03);
}

.categoryimagecontainer {
    height: 32px;
    width: 32px;
    min-width: 32px;
    border: 0px solid white;
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    max-height: 100%;
    max-width: 100%;
    overflow: hidden;
    position: relative;
    border-radius: 0%;
    box-sizing: border-box;
}

.categoryimage {
    object-fit: cover;
    height: 100%;
    transition: opacity 400ms ease 0s;
    width: 100%;
    border: 0;
    box-sizing: border-box;
}

.filtercategoryname {
    font-weight: 600;
    font-size: 18px;
    margin-left: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
    display: block;
}

.chainimagecontainer {
    width: 32px;
    height: 32px;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid white;
    box-sizing: border-box;
    display: block;
}

.chainimagediv {
    height: 32px;
    width: 32px;
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    max-height: 100%;
    max-width: 100%;
    overflow: hidden;
    position: relative;
    border-radius: 50%;
    background-color: white;
    box-sizing: border-box;
}

.chainimg {
    object-fit: cover;
    height: 19px;
    /* width: 18px; */
    box-sizing: border-box;
}

.assetresults {
    flex: 1 0 0%;
    padding: 0px 28px;
    min-width: 0px;
    border-left: 0px solid white;
    box-sizing: border-box;
    display: block;
}

.assetresultsheader {
    flex-flow: row wrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin: 24px 0px 10px;
    max-width: none;
}

.searchcontainer {
    margin: 8px 16px 8px 0px;
    flex: 1 0 0%;
    cursor: text;
    display: flex;
    background-color: white;
    border-radius: 10px;
    border: 1px solid white;
    width: 100%;
    padding: 12px;
    box-sizing: border-box;
}

.searchiconcontainer {
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
    margin-right: 8px;
    box-sizing: border-box;
    cursor: pointer;
}

.searchicon {
}

.searchinput {
    font-size: inherit;
    line-height: inherit;
    min-height: inherit;
    background-color: transparent;
    border: none;
    outline: none;
    width: 100%;
    color: inherit;
    font: inherit;
    margin: 0px;
    box-sizing: border-box;
    -webkit-writing-mode: horizontal-tb !important;
    cursor: text;
    font-size:16px;
}

.headerdropdowns {
    margin-top: 0px;
    flex-wrap: nowrap;
    display: flex;
    -webkit-box-pack: end;
    justify-content: flex-end;
    z-index: 3;
    max-width: 100%;
    box-sizing: border-box;
}

.dropdowncontainer {
    order: 1;
    width: initial;
    -webkit-box-flex: 1;
    flex-grow: 1;
    box-sizing: border-box;
    display: block;
}

.dropdowninputcontainer {
    margin-right: 8px;
    height: 50px;
    font-size: inherit;
    line-height: inherit;
    min-height: inherit;
    cursor: pointer;
    display: flex;
    background-color: white;
    border-radius: 10px;
    border: 1px solid white;
    width: 100%;
    padding: 12px;
    box-sizing: border-box;
}

.dropdowninput {
    background-color: transparent;
    border: none;
    width: 100%;
    color: inherit;
    font: inherit;
    margin: 0px;
    box-sizing: border-box;
    -webkit-writing-mode: horizontal-tb !important;
}

.downarrowcontainer {
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
    margin-left: 12px;
    box-sizing: border-box;
}

.downarrowarrowicon {
    color: #141414;
}

.profileMain .assetmaster {
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    min-height:100vh;
    gap: 10px;
    transition: all 300ms ease-in-out;
}

.assetresultsblock {
    padding: 16px 0px;
    box-sizing: border-box;
    display: block;
}

.assetresultsblockmargin {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    display: block;
}

.assetgridcontainer {
    width: 100%;
    box-sizing: border-box;
    display: block;
}

.assetgrid {
    position: relative;
    width: 100%;
    max-width: 100%;
    height: 469px;
    max-height: 469px;
    box-sizing: border-box;
    display: block;
}

.assetgridcell {
    width: 300px;
    position: absolute;
    writing-mode: horizontal-tb;
    top: 0px;
    box-sizing: border-box;
    display: block;
}

.styledcontainer {
    box-sizing: border-box;
    display: block;
}


.profileDisplay {
    align: center;
}


.userName {
    font-size: 25px;
    color: black;
}

.showAccount {
    font-size: 12px;
    color: grey;
}

.tooltip {
    font-size: 1.5rem;
    color: black;
    z-index: 1080;
    display: block;
    font-family: var(--bs-font-sans-serif);
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    word-wrap: break-word;
}

.tooltip-inner {
    color: black;
    border-radius: 10px;
    background-color: white;
    padding:0.25rem 0.5rem;
}

.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before, .bs-tooltip-bottom .tooltip-arrow::before {
    border-bottom-color: white !important;
}

.tooltip-arrow {
    display: block;
    width: 0.8rem;
    height: 0.4rem;
    top:0;
}

.mobilefilterbutton {
    display: none !important;
}

.headerbuttonsgrid {
    display: grid;
    grid-template-columns: 1fr;
    -webkit-box-align: center;
    align-items: center;
    padding: 0px 8px;
    width: 100%;
    /* position: sticky; */
    top: 72px;
    z-index: 40;
    left: 0px;
    height: 0px;
    /*margin-bottom: calc(-10px);*/
    transition: opacity 0.2s ease-in 0s;
}

.headerbuttonsflex {
    display: flex;
    justify-content: flex-end;
    max-height: 100%;
    /*margin-top:10px;*/
}

.headerbuttonscontainer {
    width:fit-content;
    display:flex;
}

.headerbutton {
    display: inline-flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    /*border-radius: 10px;*/
    -webkit-box-pack: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    padding: 12px;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(229, 232, 235);
    color: rgb(112, 122, 131);
    transition:all 200ms ease-in-out;
}

.headerbutton:not(:first-child) {
    border-left: none;
}

.headerbutton:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.headerbutton:last-child {
    border-top-right-radius:10px;
    border-bottom-right-radius:10px;
}

.headerbutton:hover {
    color:#00ffcd;
    transform:scale(1.1);
    cursor:pointer;
}

.resetfilters {
    display: flex;
    padding: 9px;
    margin-right: -9px;
    transition:all 200ms ease-in-out;
}

.resetfilters:hover {
    color:#00ffcd;
}

.filterback {
    display: none;
    align-items: center;
    align-content: center;
    padding: 10px;
}

.filterbackicon {
    color: black !important;
    font-size: 30px !important;
}

.tippy-box[data-theme~='white-bold'] {
    background-color: white;
    color: black;
    font-size: 16px;
    font-weight:500;
}

.tippy-box[data-theme~='white-bold'][data-placement^='bottom'] > .tippy-arrow::before {
    border-bottom-color: white;
}

.sharedropdown {
    
}

.sharelist {
    margin: 0 !important;
    padding: 0px !important;
}

.sharelist li div {
    display: flex;
    align-items: center;
    flex-direction: row;
    background: white;
    color: black;
    font-size: 20px;
    font-weight: 500;
    padding: 15px;
    cursor: pointer;
    transition: all 200ms ease-in-out;
}

.sharelist li:first-child div {
    border-top-left-radius: 12px;
    border-top-right-radius:12px;
}

.sharelist li:last-child div {
    border-bottom-left-radius:12px;
    border-bottom-right-radius:12px;
}

.sharelist li:nth-child(2) {
    border-bottom: 1px solid lightgrey;
    border-top: 1px solid lightgrey;
    border-radius:0px;
}

.sharelist li div:hover {
    box-shadow:0px 0px 5px black;
    transform:scale(1.01);
}

.shareitem img {
    width:30px;
    height:30px;
    margin-right:8px;
}

.resetfilterIcon {
    font-size: 30px !important;
}

.imageinputcontainer input {
    display:none;
}

.noNFTs {
    color:white;
    font-size:25px;
    font-weight:500;
}

.profileMain .itemactivityframe {
    width: 100%;
}

.profileMain .assethistorypanel .scrollboxcontainer {
    max-height:50vh;
}

.activityMaster {
    display:flex;
}

.eventNFT {
    width:100%;
    height:100%;
    padding:10px;
}

.eventNFTImageContainer {
    width: 100px;
    /* height: 100px; */
    max-width: 100%;
    max-height: 100%;
    border-radius:15px;
}

.eventNFTImageContainer .loadingNFT {
    width:50px;
    height:50px;
}

.eventNFTImage {
    object-fit: contain;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    border-radius:15px;
}

.eventNFTInfo {
    display:flex;
    flex-direction: column;
    margin-left:5px;
    color:white;
}

.eventNFTCreator {
    font-size:14px;
    color:lightgrey;
}

.eventNFTCreator {
    display:flex;
    flex-direction: row;
    align-items: center;
}

.eventNFTVerified {
    width:17px;
    height:17px;
    margin-left: 2px;
}