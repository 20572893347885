@media only screen and (max-width:630px) {
    .notfoundmaster h1 {
        font-size:70px;
        margin-bottom:30px;
    }
    .notfoundmaster h2 {
        margin-bottom:20px;
    }
    .notfoundmaster h3 {
        margin-bottom:20px;
    }
    .notfoundmaster .flexbox {
        height: 100%;
    }
}
@media only screen and (max-width:500px) {
    .notfoundmaster .buttonflex {
        flex-direction:column;
    }
}
.notfoundmaster * {
    box-sizing:border-box;
}
.notfoundmaster {
    color: white;
    text-align: center;
}
.notfoundmaster h1 {
    color: white;
    font-size: 60px;
    filter: drop-shadow(2px 1px 2px black);
    margin: 0;
    margin-bottom: 10px;
}
.notfoundmaster h2 {
    color: white;
    font-size: 40px;
    filter: drop-shadow(2px 1px 2px black);
    margin: 0;
    margin-bottom: 10px;
}
.notfoundmaster h3 {
    color: white;
    font-size: 35px;
    filter: drop-shadow(2px 1px 2px black);
    margin: 0;
    margin-bottom: 10px;
}
.notfoundmaster a {
    color:#00ffcd;
    text-decoration:none;
}
.notfoundmaster a:hover {
    color: #01BE99
}
.notfoundmaster:before {
    content: '';
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-image: url('https://cineteria.com/wp-content/uploads/2021/04/cineteria-background-tile.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    filter: blur(8px);
    transform: translate(-50%);
    -webkit-filter: blur(8px);
    z-index: -9;
}
.notfoundmaster .flexbox {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding:20px;
    height: 100vh;
    font-family: "Open Sans Semibold", Roboto !important;
}
.notfoundmaster .material-icons {
    color: black;
    font-family: 'Material Icons' !important;
}
.notfoundmaster .buttonflex {
    display:flex;
    flex-direction:row;
    justify-content:center;
}
.notfoundmaster .buttonflex a:first-child button {
    color: black;
    background-color: white;
    border: 2px black solid;
    border-radius: 15px;
    padding: 15px;
    font-size: 25px;
    font-weight: 600;
    margin: 10px;
    filter: drop-shadow(0px 2px 2px #1a1a1a);
    cursor: pointer;
    transition: all 200ms ease-in-out;
}
.notfoundmaster .buttonflex a:last-child button {
    color: black;
    background-color: #00ffcd;
    border: 2px black solid;
    border-radius: 15px;
    padding: 15px;
    font-size: 25px;
    font-weight: 600;
    margin: 10px;
    filter: drop-shadow(0px 2px 2px #1a1a1a);
    cursor: pointer;
    transition: all 200ms ease-in-out;
}
.notfoundmaster .buttonflex a {
    cursor: pointer;
    transition: all 200ms ease-in-out;
}
.notfoundmaster .buttonflex a:hover {
    transform:scale(1.1);
}