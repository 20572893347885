
/*MAIN CSS*/
.unstyledbutton {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  font-size: 100%;
  font-family: inherit;
  border: 0px;
  padding: 0px;
  background: inherit;
  color: inherit;
  font: inherit;
  margin: 0px;
  box-sizing: border-box;
  appearance: auto;
  -webkit-writing-mode: horizontal-tb !important;
  text-rendering: auto;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  text-align: center;
  cursor: default;
}

.darkButton {
  color:white !important;
  background: rgb(43,43,43);
  background-color: rgb(43,43,43) !important;
  background: linear-gradient(90deg, rgba(43,43,43,1) 0%, rgba(112,112,112,1) 100%);
  border:none;
}

.darkButton:focus, .darkButton:hover {
  box-shadow:0px 0px 10px 0px gray !important;
}

button.green {
  color:#0f0f0f;
  background: #00ffcd;
  background: linear-gradient(90deg, rgba(0,185,149,1) 0%, rgba(0,255,205,1) 57%, rgba(0,255,205,1) 100%);
  border:none;
}

button .green:focus, button .green:hover {
  box-shadow:0px 0px 10px 0px gray !important;
}


@font-face {
  font-family: "Open Sans Semibold";
  src: url("./fonts/OpenSans-Semibold.eot") format("eot"),url("./fonts/OpenSans-Semibold.woff") format("woff"),url("./fonts/OpenSans-Semibold.ttf") format("truetype"),url("./fonts/OpenSans-Semibold.svg#OpenSans-Semibold") format("svg");
  font-weight: normal;
  font-style: normal;
}

/*
@font-face {
  font-family: "Open Sans";
  src: url("/wp-content/plugins/marketplace/fonts/Open-Sans-Regular.eot?") format("eot"),url("/wp-content/plugins/marketplace/fonts/Open-Sans-Regular.woff") format("woff"),url("/wp-content/plugins/marketplace/fonts/Open-Sans-Regular.ttf") format("truetype"),url("/wp-content/plugins/marketplace/fonts/OpenSans-Semibold.svg#Open-Sans-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}
*/

@font-face {
  font-family: "Open Sans Medium";
  src: url("https://cineteria.com/wp-content/plugins/marketplace/fonts/Open-Sans-Medium.eot?") format("eot"),url("https://cineteria.com/wp-content/plugins/marketplace/fonts/Open-Sans-Medium.woff") format("woff"),url("https://cineteria.com/wp-content/plugins/marketplace/fonts/Open-Sans-Medium.ttf") format("truetype"),url("https://cineteria.com/wp-content/plugins/marketplace/fonts/OpenSans-Semibold.svg#Open-Sans-Medium") format("svg");
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: "Open Sans Semibold",roboto,sans-serif !important;
  font-size: 14px;
}

body.noScroll {
  overflow:hidden;
}

::-webkit-scrollbar {
  width:4px;
}

::-webkit-scrollbar-track {
  background:#1a1a1a;
}

::-webkit-scrollbar-thumb {
  background:#00daae;
  border-radius:15px;
}

::-webkit-scrollbar-thumb:hover {
  background:#00c9a1;
}

.shimmer {
  background-image: linear-gradient( 90deg, #EEEEEE 0%, #EEEEEE 40%, #DDDDDD 50%, #DDDDDD 55%, #EEEEEE 65%, #EEEEEE 100% ) !important;
  background-size: 400% !important;
  animation: shimmer 1500ms infinite;
}

@keyframes shimmer {
  from {
      background-position: 100% 100%;
  }

  to {
      background-position: 0 0;
  }
}

.flexCenter {
  display:flex;
  align-items: center;
  justify-content: center;
  flex-direction:column;
}

.flexCenter.flexRow {
  flex-direction:row;
}

/*NAV CSS*/

@media(hover: hover) and (pointer: fine) {
  .navbar-nav a:hover {
      color: #00ffcd !important;
      transform: scale(1.07);
  }
  .createnavbttn:hover {
      transform: scale(1.1);
  }
  .searchdropdown a:hover {
      transform: scale(1.03);
      filter: drop-shadow(0px 2px 2px #1a1a1a);
  }
  .offcanvas-body a:hover {
      color: #00ffcd;
  }
}

@media(hover:none) and (any-pointer: coarse) {
  .navbar-nav a {
      transition: 200ms 0.1s;
  }
  .navbar-nav a:active {
      color: #00ffcd !important;
      transform: scale(1.07);
      transition: 100ms;
  }
  .createnavbttn {
      transition: 200ms 0.1s;
  }
  .createnavbttn:active {
      transform: scale(1.1);
      transition: 100ms;
  }
  .searchdropdown a {
      transition: 200ms 0.1s;
  }
  .searchdropdown a:active {
      transform: scale(1.03);
      box-shadow: 0px 2px 2px black;
      transition: 100ms;
  }
  .offcanvas-body a {
      transition: 100ms 0.1s;
  }
  .offcanvas-body a:active {
      color: #00ffcd !important;
      transition: 100ms;
  }
}

@media only screen and (max-width:1200px) {
  .usernameContainer {
    display:none;
  }
}

@media only screen and (max-width:660px) {

  .mainsearchbar {
      display: none !important;
  }

  .mobilesearchbar {
      display: inline-flex !important;
      border: 2px lightgrey solid;
      border-radius: 15px;
      margin-bottom: 20px;
  }

  .navsearchcontainer {
      width: 100%;
      max-width: none !important;
  }

  .mobileusernav {
      display: flex !important;
  }

  .mobileusernav .profilelink {
      display: block !important;
      box-shadow: 0px 2px 10px black;
      border-radius: 15px;
  }

  .mobileusernav .profilelink span {
      color: black;
  }

  .profilelink {
      display: none;
  }
}

a {
  text-decoration: none !important;
}

nav {
  box-shadow: 0px 0px 15px black;
}

.navbar {
  background-color:#1a1a1a;
}

.navlogo {
  width: 60px; filter: drop-shadow(2px 4px 3px black);
}

.navbar-nav a {
  color: white !important;
  font-weight: 500;
  transition: all 200ms ease-in-out;
  margin: 5px;
}

.navbar-nav .active {
  color:#00ffcd !important;
}

.navbaritems > div {
  margin-left: 40px;
}

.navbarcontainer {
  flex-wrap: nowrap !important;
  font-family: var(--bs-body-font-family) !important;
}

.navsearchcontainer {
  font-size: 20px;
  background-color: white;
  padding: 10px;
  border-radius: 15px;
  max-width: 90%;
  min-width: 280px;
}

.navbar-brand {
  min-width: 100px !important;
}

.navbar-collapse {
  flex-grow: 0;
}

.mainsearchbar {
  width: 100%;
}

.mobilesearchbar {
  width: 100%;
  display: none;
}

.mobilesearchdropdown {
  overflow-y: scroll;
  max-height: 500px;
  box-shadow: 0px 2px 5px black;
}

.navsearch {
  background-color: transparent;
  border: none;
  outline: none;
  font-weight: 500;
  width: 100%;
}

.createbttncont {
  margin-left:10px;
}

.createnavbttn {
  background-color: #00ffcd !important;
  padding: 25px !important;
  line-height: 0px !important;
  color: black !important;
  font-weight: 500 !important;
  border-radius: 25px;
  transition: all 200ms ease-in-out;
}

.profilepicture {
  object-fit: cover;
  height: 100% !important;
  width: 100%;
  border-radius: inherit !important;
}

body .mainsearchbar .searchdropdown {
  display:none !important;
}

.searchicon {
  color:black;
}

.searchdropdown {
  margin-top: 20px;
  background-color: white;
  padding: 10px;
  border-radius: 15px;
  z-index: 2;
  width: inherit;
  filter: drop-shadow(0px 2px 2px #1a1a1a);
  max-height: 50vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

  .searchdropdown ul {
      border-top: 1px black solid;
      border-bottom: 1px black solid;
      margin: 0 !important;
      padding: 0 !important;
  }

  .searchdropdown li {
      /*margin: 0 !important;
      padding: 0 !important;*/
  }

.searchusers li {
  display: flex;
  padding: 8px;
}

.searchdropdown a {
  display: flex;
  background-color: white;
  border-radius: 10px;
  padding: 8px;
  justify-content: space-between;
  cursor:pointer;
  transition: all 200ms ease-in-out;
}

.searchnftcontainer {
  margin-bottom: 30px;
}

.searchheader {
  color: darkgray;
  font-size: 20px;
}

.searchnft li {
  padding: 8px;
}

.searchnft a {
  align-content: center;
  display: flex;
  align-items: center;
}

.searchnftnamecontainer {
  display: flex;
  flex-direction: column;
}

.searchnftname {
  color: black;
  font-size: 20px;
  font-family: roboto;
}

.searchcategorycontainer {
  color: black !important;
  display: flex;
  line-height: 16px;
  align-items: center;
}

.searchcategory {
  color: darkgray;
  font-size: 14px;
}

.searchcategoryicon {
  color: darkgrey !important;
  font-size: 16px !important;
  margin-left: 2px !important;
}

.searchnftimgcontainer {
  width: 50px;
  margin-left: 20px;
  border: 2px black solid;
  display:flex;
}

.searchnftimg {
  height: auto;
  width: 100%;
}

.searchprofileimgcontainer {
  width: 45px;
  height: 45px;
  max-height: 45px;
  border: double 3px transparent;
  border-radius: 80px;
  background-image: linear-gradient(#1a1a1a, #1a1a1a), radial-gradient(circle at top left, blue,red);
  background-origin: border-box;
  background-clip: content-box, border-box;
  display: flex;
}

.searchprofileimg {
  border-radius: inherit !important;
  object-fit: cover;
  height: 100% !important;
  width: 100%;
}

.searchprofilenamecontainer {
  display: flex;
  margin-left: 10px;
  align-items:center;
}

.searchprofilename {
  color: black;
  font-family: 'Open Sans Semibold';
  font-size: 20px;
}

.searchownertitle {
  color:lightgray;
  margin-bottom:5px;
  font-size:12px;
}

.searchownercontainer {
  color: black;
  font-size: 15px;
  font-weight: 500;
  padding: 16px;
}

.searchprice {
  color:black;
  font-family:roboto;
  font-size:20px;
}

.searchethicon {
  width:15px;
  margin-left: 5px;
  height: 100%;
}

.searchnotfound {
  color: white;
  font-size: 16px;
}

.navbar-toggler:focus {
  box-shadow:0 0 0 0 !important;
}

.navbar-toggler-icon {
  background-color: #1a1a1a;
  border: 2px white solid;
  border-radius: 10px;
  color: white;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

body #root .offcanvas {
  width: 100%;
  visibility: visible;
  transition: all 300ms ease-in-out !important;
}

body .offcanvas .active {
  color:#00ffcd !important;
}

body.offcanvastransition {
  transition: all 300ms ease-in-out !important;
}

.offcanvas-body .mobilenav {
  padding-left: 0 !important;
  background-color: white;
  filter: drop-shadow(2px 2px 4px black);
  border-radius: 10px;
  margin: 0 !important;
  margin-top: 15px !important;
}

.offcanvas-body li {
  border-bottom: 2px lightgrey solid;
}

.offcanvas-body .mobilenav a {
  color: black;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.offcanvas-body i {
  margin-left: 2px;
  color: black;
  line-height: 16px;
}

.offcanvas-body .createbttn {
  background-color: #00ffcd;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  justify-content: center;
  font-size: 30px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mobileusernav {
  display: none;
  justify-content: center;
}

.mobileusernav a {
  color:black;
}

.mobileusernav button {
  background-color: white;
  border: 2px lightgrey solid;
  border-radius: 12px;
}

.usernavimg {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  padding: 10px;
  border-radius: 12px;
  max-height: 70px;
  white-space: nowrap;
}

.usernav, .mobilenav, .navbar-nav {
  color: white;
  font-size: 25px;
  margin: 15px 0 15px 20px !important;
  padding-left: 2rem !important;
  list-style-type: none !important;
}

.sharelist {
  list-style-type: none !important;
}

.verifiedprofile {
  max-width: none;
  align-items: center;
  width: 25px;
  margin-left: 2px;
}

.usernameContainer {
  color: white;
  display: flex;
  margin-left: 10px;
  font-size: 18px;
  font-weight: 500;
  align-items:center;
}

.creatorsearchnamecont {
  display: flex;
  align-items:center;
}

.creatorsearchname {
  color:black;
  font-size:16px;
  margin-left:4px;
}

.ownersearchnamecont {
  display: flex;
  align-items: center;
}

.searchuserverified {
  width: 23px;
  margin-left: 3px;
}

.creatorsearchverified {
  width: 18px;
  margin-left: 2px;
}

.ownersearchverified {
  width: 16px;
  margin-left: 2px;
}

.mobileverifiedprofile {
  width: 23px;
  margin-left: 4px;
}

.tippy-box[data-theme~='transparent'] {
  background-color: transparent;
}

.headerNotificationsMaster {
  display:flex;
  justify-content: center;
  align-items: center;
  cursor:pointer;
}

.notificationBell {
  color:white;
  font-size:30px;
}

.notificationNumber {
  height:25px;
  width:25px;
  display:flex;
  align-items:center;
  align-content: center;
  justify-content: center;
  background-color:#00ffcd; 
  border-radius:20px;
  color:black;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  position: relative;
  bottom: 6px;
  left: 36px;
  padding: 3px;
  border-style: solid;
  border-width: 2px;
  border-color:#1a1a1a;
}

.notificationNumber:empty {
  display: none;
}

.notificationsContainer {
  background-color: #1a1a1a;
  color:white;
  border-radius:15px;
  height:600px;
  width:400px;
  overflow-y: hidden;
  box-shadow: 0px 0px 10px 5px black;
}

.notificationsContent::-webkit-scrollbar {
  width:3px;
}

.notificationsContent::-webkit-scrollbar-track {
  background:#1a1a1a;
}

.notificationsContent::-webkit-scrollbar-thumb {
  background:#00ffcd;
  border-radius:15px;
}

.notificationsContent::-webkit-scrollbar-thumb:hover {
  background:#00c9a1;
}

.notificationsContent {
  height:600px;
  width:400px;
  overflow-y:scroll;
}

.notification {
  cursor:pointer;
  color:white;
  display:block;
  padding:15px;
  border-bottom:2px solid #252525;
  transition: all 100ms ease-in-out;
}

.notification:hover {
  box-shadow: 0px 0px 10px 0px black;
}

.notificationsLogInContainer {
  display:flex;
  align-items: center;
  justify-content: center;
  height:100%;
}

.notificationsLogInButton {
  padding:20px;
  text-align: center;
  width:200px;
  background: linear-gradient(90deg, rgba(0,255,205,1) 0%, rgba(0,255,205,1) 57%, rgba(0,185,149,1) 100%);
  color:black;
  border-radius:15px;
  font-size:24px;
  font-weight:700;
  border:2px solid black;
  box-shadow: 0px 0px 15px 0px black;
  cursor:pointer;
  transition: 200ms;
}

.notificationsLogInButton:active {
  transform:scale(1.1);
}

.notificationImage.nft img {
  border-radius:5px;
  height:50px;
  width:50px;
  object-fit:cover;
}

.notificationImage.user img {
  border-radius: 50px;
  height:50px;
  width:50px;
}

.notificationloading {
  width:50px;
  height:50px;
}

.notificationloading.nft {
  border-radius:15px;
}

.notificationloading.user {
  border-radius:50px;
}

.notificationText {
  margin-left:10px;
  font-size:15px;
  font-weight:600;
  color:white;
}

.notificationNew {
  border-radius: 50px;
  min-width: 12px;
  min-height: 12px;
  margin-left:10px;
  background-color: #00ffcd;
}

.notificationTime {
  color:lightgrey;
  font-size:13px;
  margin-left:60px;
}

.wethContainer {
  display:inline-flex;
  align-items: center;
  justify-content: center;
}

.notificationText .ethimg {
  margin-right:2px;
  margin-left:3px;
  width:9px;
}

/*ASSET CARD*/

@media(hover: hover) and (pointer: fine) {
  .assetCard {
      transition: all 200ms ease-in-out;
  }
  .assetCard:hover {
      transform: scale(1.1);
  }
  .assetname {
      transition: all 200ms ease-in-out;
  }
  .assetname:hover {
      color: white !important;
  }
  .artistname {
      transition: all 200ms ease-in-out;
  }
  .artistname:hover {
      color: #00ffcd !important;
      /*font-size: 16px;*/
  }
  .likeicon {
      transition: all 200ms ease-in-out;
  }
  .likeicon:hover {
      color: #00ffcd;
  }
}

@media(hover:none) and (any-pointer: coarse) {
  .assetCard {
      transition:200ms 0.1s;
  }
  .assetCard:active {
      transform:scale(1.05);
      transition:100ms;
  }
  .assetname {
      transition: 100ms 0.1s;
  }
  .assetname:active {
      color: white !important;
      transition: 100ms;
  }
  .artistname {
      transition: 100ms 0.1s;
  }
  .artistname:active {
      color: #00ffcd !important;
      transition: 100ms;
  }
  .likeicon {
      transition: 100ms 0.1s;
  }
  .likeicon:active {
      color: #00ffcd;
      transition: 100ms;
  }
}

.assetCard {
  display: flex;
  flex-direction: column;
  background-color: black;
  width: 300px;
  height: 100%; /*Might Have to remove this to prevent long asset card*/
  margin-bottom: 20px;
  margin-top: 20px;
  position: relative;
  border-radius: 11px 11px 10px 10px;
  box-sizing: border-box;
  z-index: 2;
  font-family: 'Open Sans Semibold' !important;
  font-weight: 600;
  box-shadow: 0px 0px 10px black;
}

.styledlink {
  pointer-events: initial;
  display: flex;
  flex-direction: column;
  height: 100%;
   /*overflow:hidden;*/
  text-decoration: none;
  box-sizing: border-box;
  cursor: pointer;
}

.assetCardContent {
  height: 298px;
  width: 300px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  position: relative;
  display: block;
  box-sizing: border-box;
}

.assetmedia {
  min-height: inherit;
  border-radius: inherit;
  height: 100%;
  width: 100%;
  display: block;
  box-sizing: border-box;
}

.flexcenteraligned {
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  height: 100%;
  min-height: inherit;
  width: 100%;
  border-radius: inherit;
  box-sizing: border-box;
}

.assetCard .imagecontainer {
  height: 100%;
  width: 300px;
  border-radius: inherit;
  align-items: center;
  display: flex;
  justify-content: center;
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
}

.assetimage {
  object-fit: cover;
  width: 100%;
  height: 100% !important;
  max-width: 100%;
  max-height: 100%;
  border-radius: 0px;
  opacity: 1;
  transition: opacity 400ms ease 0s;
  border: 0;
  box-sizing: border-box;
}

.flexspacebetween {
  height: 126px;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.cardspacebetween {
  justify-content: space-between;
  display: flex;
  padding: 12px;
  box-sizing: border-box;
}

.assetinfo {
  margin-right: 12px;
  min-width: 0px;
  width: 60%;
  box-sizing: border-box;
}

.flexassetname {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  margin-top: -2px;
}

.assetnameblock {
  max-width: 200px;
  box-sizing: border-box;
  display: block;
}

.assetnamelink {
  color: white;
  font-weight: 500;
  font-size: 18px;
  text-decoration: none;
  box-sizing: border-box;
}

.assetname {
  color: white;
  font-weight: 500;
  font-size: 18px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-sizing: border-box;
  display: block;
}

  

.artistnamecontainer {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  box-sizing: border-box;
}

.artistnamelink {
  color: white;
  font-weight: 500;
  font-size: 18px;
  text-decoration: none;
  box-sizing: border-box;
}

.artistname {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #bab8b7;
  font-size: 15px;
  letter-spacing: 0.1px;
  transform: scale(1);
  box-sizing: border-box;
  display: inline-block;
}

  

.pricecontainer {
  flex-direction: column;
  display: flex;
  width: 40%;
  align-items: flex-end;
  box-sizing: border-box;
}

.priceflexcontainer {
  flex: 1 0 0%;
  align-items: flex-end;
  flex-direction: column;
  display: flex;
  box-sizing: border-box;
}


.pricetext {
  font-weight: 400;
  font-size: 12px;
  color: white;
  text-align: right;
  box-sizing: border-box;
  margin-bottom: 1px;
}

.pricenumcontainer {
  font-size: 14px;
  height: initial;
  -webkit-box-pack: end;
  justify-contest: flex-end;
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  font-weight: 600;
  color: white;
  width: fit-content;
  max-width: 100%;
  box-sizing: border-box;
}

.ethlink {
  text-decoration: none;
  box-sizing: border-box;
  cursor: pointer;
}

.ethcontainer {
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  width: 14px;
  height: 14px;
  box-sizing: border-box;
}

.ethimg {
  object-fit: contain;
  width: 11px;
  height: auto;
  border: 0;
  box-sizing: border-box;
}

.pricenum {
  margin-left: 0.3em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-sizing: border-box;
  display: block;
}

.pricerawsymbol {
  font-weight: normal;
  box-sizing: border-box;
}

.cardfooterannotations {
  font-size: 11px;
  display: flex;
  -webkit-box-pack: end;
  justify-content: flex-end;
  box-sizing: border-box;
}

.cardfooterflex {
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  font-weight: 500;
  padding: 12px;
  height: 42px;
  width: 100%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background: linear-gradient(black,gray);
  -webkit-box-pack: justify;
  justify-content: space-between;
  box-sizing: border-box;
}

.ownerflex {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  box-sizing: border-box;
  overflow: hidden;
}

.ownertextcontainer {
  max-width: 150px;
  display: inline-block;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: white;
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ownercontainer {
  display: inline-block;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: white;
  font-size: 12px;
}

.likescontainer {
  font-weight: 500;
  font-size: 12px;
  color: black;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  box-sizing: border-box;
}

.likeicon {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
  color: white;
  text-rendering: optimizelegibility;
  cursor: pointer;
  font-feature-settings: "liga" !important;
  font-size: 20px !important;
  box-sizing: border-box;
}

.likesamount {
  -webkit-box-pack: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  margin-left: 5px;
  box-sizing: border-box;
  color: white;
  font-size: 15px;
}

.cardheader {
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  font-weight: 500;
  padding: 12px;
  height: 35px;
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  background: black;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.categoryblock {
  max-width:80%;
  display:block;
}

.categorycontainer {
  display: flex;
  align-items: center;
  font-size: 11px;
  color: white;
  font-weight: 500;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  /*line-height: 15px;*/
}

.categoryname {
  color: white;
}

.categoryimgcontainer {
  margin-left: 4px;
  width: 16px;
}

.categoryimg {
  max-width: 100%;
  height: auto;
}

.creatorverified {
  width: 19px;
  margin-left: 3px;
}

.ownerverified {
  width: 15px;
  margin-left: 3px;
}

.timeLeftCont i {
  color: lightgray;
  font-size: 18px !important;
}

.timeLeft {
  color: lightgrey;
  font-size: 12px;
  margin-left:5px;
  white-space: nowrap;
}

/*Audio Player*/

.musiccontainer {
  background-color:#00ffcd;
  border-radius: 15px;
  box-shadow: 0px 0px 10px 0px rgba(252, 169, 169, 0.6);
  display:flex;
  padding: 20px 30px;
  position: relative;
  margin: 100px 0;
  z-index: 10;
}

.audioimgcontainer {
  position: relative;
  width: 110px;
}

.audioimgcontainer img {
  width:inherit;
  height:110px;
  border-radius:12px;
  object-fit:cover;
  position:absolute;
  bottom: 0;
  left: 0;
}

.audionavbttn {
  background-color:#00ffcd;
  border: 0;
  color: #dfdbdf;
  font-size: 20px;
  cursor: pointer;
  padding:10px;
  margin:0 20px;
}

.audionavplay {
  color: #cdc2d0;
  font-size:30px;
}

.audionavbttn:focus {
  outline: 0;
}

.musicinfo {
  color: white;
}

.loadingNFT {
  animation: loading-gradient;
  animation-duration: 1.8s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  background: linear-gradient(90deg, #1a1a1a 0px, #252525 40px, #1a1a1a 80px);
  background-size: 1000px 640px;
}

@keyframes loading-gradient {
  0% {
    background-position: -400px;
  }
  100% {
    background-position: 400px;
  }
}

.loadingNFT .cardfooterflex {
  background:none;
}

/*USERNAV*/

@media(hover: hover) and (pointer: fine) {
  .usernavbutton:hover {
      filter: drop-shadow(0px 0px 6px black);
  }
  .dropdowncontent li:hover {
      transform: scale(1.1);
  }
  .dropdowncontent a:hover {
      color: #00ffcd;
  }
}

@media(hover:none) and (any-pointer: coarse) {
  .usernavbutton {
      transition: 200ms 0.1s;
  }
  .usernavbutton:active {
      filter: drop-shadow(0px 0px 6px black);
      transition: 100ms;
  }
  .dropdowncontent li {
      transition: 200ms 0.1s;
  }
  .dropdowncontent li:active {
      transform: scale(1.02);
      transition: 100ms;
  }
  .dropdowncontent a {
      transition: 200ms 0.1s;
  }
  .dropdowncontent a:active {
      color: #00ffcd;
      transition: 100ms;
  }
  body .dropdowncontent li {
      transition: 200ms 0.1s;
  }
  body .dropdowncontent li:active {
      transform: scale(1.01);
      transition: 100ms;
  }
}

@media only screen and (max-width:660px) {
  body .dropdowncontent li {
      background-color: white;
      border-top: 2px lightgrey solid;
      color:black;
  }

  body .dropdowncontent a {
    color:black
  }

  body .dropdowncontent li:first-child {
      border-color: transparent;
      border-bottom: 2px lightgrey solid;
      border-top:0px;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
  }

  body .dropdowncontent {
      border-radius: 10px;
      box-shadow: 0px 8px 15px black;
  }
}

  .usernavbutton {
      background-color: #1a1a1a;
      transition: all 200ms ease-in-out;
      border-radius: 10px;
  }

  .profilelink {
      padding: 0px !important;
  }

  .profilepiccontainer {
      width: 64px;
      height: 64px;
      border-radius: 50%;
      background-color: #1a1a1a;
      border: double 3px transparent;
      border-radius: 80px;
      background-image: linear-gradient(#1a1a1a, #1a1a1a), radial-gradient(circle at top left, #FFFFFF,#FFFFFF);
      background-origin: border-box;
      background-clip: content-box, border-box;
  }

  .dropdown {
      display: block;
  }

  .dropdowncontent {
      background-color: #1a1a1a;
      min-width: 160px;
      z-index: 1 !important;
      cursor: pointer;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
  }

  .dropdowncontent ul {
      margin: auto !important;
      padding: 0 !important;
  }

  .dropdowncontent li {
      border: 1px white solid;
      background-color: #1a1a1a;
      transition: all 200ms ease-in-out;
  }

  .dropdowncontent li:first-child {
      border-color: rgba(255, 255, 2555, 0.9) rgba(255, 255, 2555, 1) rgba(255, 255, 2555, 1) rgba(255, 255, 2555, 1);
      border-top-left-radius: 10px;
      border-top-right-radius:10px;
  }

  .dropdowncontent li:last-child {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
  }

  .dropdowncontent a {
      color: white;
      width: 100%;
      display: block;
      padding: 5px;
  }

  .tippy-box[data-theme~='1a'] {
      background-color: transparent;
      color: white;
      font-size: 18px;
      text-align: center;
  }

  .tippy-box[data-theme~='transparent'] {
      background-color: transparent;
  }

  #tippy-1 {
      filter: drop-shadow(0px 0px 6px black);
  }




  /*POPUP*/

  @media only screen and (max-width: 400px) {
    body .popupbutton {
        white-space: normal;
    }
}

.popup .popupoverlay {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.7);
    z-index: 71;
    display: none;
    transition: all 300ms ease-in-out;
}

.popup .popupcontent {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    background: #fff;
    border-radius: 10px;
    border: 1px solid rgba(0,0,0,0.5);
    width: 700px;
    max-width: 80%;
    z-index: 72;
    padding: 20px;
    box-sizing: border-box;
    /* font-family: 'Open Sans Medium' !important; */
    font-family: "Open Sans Semibold",roboto,sans-serif;
    transition: all 300ms ease-in-out;
}

.popup.dark .popupcontent {
  background:#191919;
  color:darkgray;
  box-shadow: 0px 0px 5px -1px white;
  transition: all 300ms ease-in-out;
}

.popup.dark .popupcontent:hover {
  /* box-shadow: 0px 0px 10px -3px white; */
}

.popup .popupclose {
    cursor: pointer;
    font-size: 30px;
    padding: 2px;
    /*
    position: absolute;
    right: 20px;
    top: 20px;
    width: 30px;
    height: 30px;
    background: #222;
    color: #fff;
    font-size: 25px;
    font-weight: 600;
    line-height: 30px;
    text-align: center;
    border-radius: 50%;
        */
}

.popup.active .popupoverlay {
    display: block;
}

.popup.active .popupcontent {
    transition: all 300ms ease-in-out;
    transform: translate(-50%, -50%) scale(1);
}

.popup h3 {
    font-size: 20px;
    margin: 0px !important;
}

.popup.dark h3 {
  color:darkgray;
}

.popup h4 {
  font-size: 18px;
  padding:0px;
}

.popup p {
    margin-top:8px;
    margin-bottom:12px !important;
    font-size: 15px !important;
    font-weight: 400;
    max-width:500px;
    color:black !important;
}

.popup.dark p {
  color: darkgray !important;
}

.popup a {
    color: #3ebaa2;
    font-weight: 700;
}

.popup a:hover {
    color: #00ffcd;
}

.popupbutton {
    text-decoration: none;
    background: white;
    border-color: rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    color: rgba(0, 0, 0, 0.7);
    transition: all 200ms ease-in-out;
    padding: 10px;
    margin-right: 6px;
    font-weight: 600;
    /*
    width: 150px;
        */
    width: 20rem;
    white-space: nowrap;
}

.popupbutton.darkButton {
    background: rgb(43,43,43);
    background: linear-gradient(90deg, rgba(43,43,43,1) 0%, rgba(112,112,112,1) 100%);
    box-shadow: 0px 0px 5px -1px white;
    border-radius: 12px;
    padding:10px;
    width:100%;
    font-size:20px;
    max-width:150px;
    margin-top:10px;
    transition: all 200ms ease-in-out;
}

.popupbutton:hover {
    /* transform: scale(1.1); */
}

.popupbutton:disabled {
    opacity: 0.5;
}

.popupicon {
    font-size: 28px !important;
    vertical-align: middle;
    margin-right:4px;
}

.popupheader {
    display:flex;
    align-items:center;
    align-content:center;
    justify-content:space-between;
    margin-bottom:5px;
}

.popup .error {
    color: #dc3545;
    font-size: 1.075em;
    width: 100%;
    text-align: center;
    margin-top: 0px;
}

.popupInputBox {
    min-width:400px;
    border-radius: 12px;
    outline: 0;
    box-shadow: 0 0 15px 4px rgb(0 0 0 / 10%);
    padding: 6px 12px;
    color: #333;
    border: 1px solid #ddd;
    transition: all 150ms ease-in-out;
}

.popupInputBox:hover, .popupInputBox:focus {
  box-shadow: 0 0 15px 4px rgba(0 0 0 / 20%);
}

.popupLoadingImg {
  width: 160px;
  margin-bottom:14px;
}

/*NAVHEADER*/

.navheader {
  white-space:nowrap;
}

body {
  font-family: var(--bs-body-font-family);
}

/*Footer*/

.footercont {
  background-color: #1a1a1a;
  text-align: center;
}

.footer {
  color:white;
  font-size:14px;
  font-weight:400;
}

/*Filter*/

.reseticon {
  font-size: 30px !important;
}

/*Other*/
.cineteriaLoading {
  width: 250px;
  height: 250px;
}

/* Gift */
.giftMaster.show {
  display:block;
}

.giftMaster {
  display:none;
  transition:all 200ms ease-in-out;
}

.giftModal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
    background: #1a1a1a;
    border-radius: 10px;
    border: 1px solid rgba(0,0,0,0.5);
    width: 700px;
    max-width: 80%;
    z-index: 9999;
    padding: 20px;
    box-sizing: border-box;
    font-family: 'Open Sans Semibold' !important;
    box-shadow: 0px 0px 10px 5px black;
    color:gray;
    transition: all 300ms ease-in-out;
}

.giftContent {
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.giftHeader {
  color:white;
  font-size:1.8em;
  margin-bottom:15px;
}

.giftMaster .assetCard {
  transform:scale(1);
}

.giftFrom {
  display: flex;
  flex-direction:row;
  align-items: center;
  text-align: center;
  gap:5px;
}

.giftMaster .verified {
  width:20px;
  height:20px;
  margin-left:5px;
}

.giftMaster .overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.7);
    z-index: 9998;
    transition: all 300ms ease-in-out;
}

.nextButton {
  color:rgb(204, 204, 204);
  font-size:24px;
  background:#0f0f0f;
  /* border:1px solid rgb(204, 204, 204); */
  width:100%;
  max-width:80%;
  text-align: center;
  padding:15px;
  border-radius:12px;
  cursor:pointer;
}

.liked {
  color: #00ffcd !important;
}

/* Loading */

/* Loading Ellipsis */
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 13px;
  /* Notice the height is the same pixels of the below div's height. This allows us to get rid of the below div's top and make the loading animation have no padding */
}
.lds-ellipsis div {
  position: absolute;
  /* top: 33px; */
  width: 13px; 
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.tippy-box[data-theme~='dark'] {
  background-color: #111111 !important;
  /* background: linear-gradient(90deg, rgba(43,43,43,1) 0%, rgb(15, 15, 15) 100%); */
  /* color:lightgray; */
  border-radius: 8px;
  box-shadow: 0px 0px 10px 2px black;
}

.tippy-box[data-theme~='dark'][data-placement^='top'] > .tippy-arrow::before {
  border-top-color: #111111;
}
.tippy-box[data-theme~='dark'][data-placement^='bottom'] > .tippy-arrow::before {
  border-top-color: #111111;
}
.tippy-box[data-theme~='dark'][data-placement^='left'] > .tippy-arrow::before {
  border-top-color: #111111;
}
.tippy-box[data-theme~='dark'][data-placement^='right'] > .tippy-arrow::before {
  border-top-color: #111111;
}