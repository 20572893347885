.loginmain {
    background-color:#1a1a1a;
}

.logincontent {
    height:100vh;
}

.loginmain h2 {
    color:lightgray;
    text-align: center;
    font-family: "Roboto", Sans-serif;
    font-size: 38px;
    font-weight: 600;
    margin: 0;
    padding: 10px;;
    line-height: 1;
}

.loginmain h4 {
    color: darkgray;
    /* color: #BCBCBC; */
    font-family: "Roboto", Sans-serif;
    font-size: 20px;
    font-weight: 600;
    text-shadow: 0px 0px 10px rgb(0 0 0 / 30%);
    text-align: center;
    margin-bottom:20px;
}

.loginmain .titleblock {
    padding-top:20px;
}

.walletbutton img {
    width:70px;
    padding:10px;
}

.wallettitle {
    display: inline;
    color: black;
    font-size: 25px;
    vertical-align: middle;
    margin:0;
    line-height:1;
}

.walletbutton {
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    width: clamp(400px, 70vw, 1000px);
    transition: all 200ms ease-in-out;
    border-radius: 20px;
    margin-bottom:10px;
}

    .walletbutton:hover {
        cursor: pointer;
        box-shadow: 0px 0px 10px 5px grey;
        transform: scale(1.1);
    }

.showAccount {
    color: white;
}

.loginmain .error {
    align-items: center;
    margin-top: 50px;
    white-space: nowrap;
    text-align: center;
}

    .loginmain .error p {
        color: white;
        text-align: center;
        font-family: roboto;
        font-weight: 500;
    }

    .error a {
        color: #00ffcd;
        text-decoration: underline;
    }

    .error a:hover {
        color:#00ffcd;
        transition: 100ms all ease-in-out;
    }
    .error div {
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .error ul {
        display:flex;
        flex-direction: column;
        align-items: baseline;
    }
    .error li:after {
        content:"❌";
        margin-left:2px;
    }

    .error li.correct {
        color:green;
    }

    .error li.correct:after {
        content:"✅";
        margin-left: 2px;
    }



.loginEmailContainer {
    /* margin-top:50px; */
}

.emailLoginForm {
    display:flex;
    flex-direction: column;
    align-items: center;
    gap:10px;
    padding-left:20px;
    padding-right:20px;
}

.emailLoginForm input {
    background-color: white;
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(208,208,208,1) 100%);
    color:black;
    border-radius: 12px;
    font-size:18px;
    padding:10px;
    border:none;
    outline:none;
    max-width:400px;
    width:100%;
    transition: all 200ms ease-in-out;
}

.emailLoginForm button {
    border-radius: 12px;
    background-color: #00ffcd;
    color:black;
    padding:10px;
    width:100%;
    font-size:20px;
    max-width:150px;
    border:none;
    margin-top:10px;
    transition: all 200ms ease-in-out;
}

.emailLoginForm button:focus, .emailLoginForm button:hover, .emailLoginForm input:focus, .emailLoginForm input:hover {
    box-shadow:0px 0px 10px 0px gray;
}

.emailLoginForm.emailError input[type=email] {
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgb(174, 95, 95) 100%);
}

.emailLoginForm.passwordError input[type=password] {
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgb(174, 95, 95) 100%);
}

.loginOr {
    color: #BCBCBC;
    font-family: "Roboto", Sans-serif;
    font-size: 40px;
    padding:20px;
    font-weight: 600;
    text-shadow: 0px 0px 10px rgb(0 0 0 / 30%);
    text-align: center;
}

.verifyEmail {
    display:flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin-top:20px;
}

.doubleButtons {
    display:flex;
    flex-direction: column;
    align-items: center;
    white-space: nowrap;
}

.doubleButtons button:nth-child(2), .doubleButtons a button {
    color:white;
    background: rgb(43,43,43);
    background: linear-gradient(90deg, rgba(43,43,43,1) 0%, rgba(112,112,112,1) 100%);  
    min-width: 170px;
}

.doubleButtons.buttonsRow {
    flex-direction: row;
    width:100%;
    justify-content: center;
    gap:15px;
}

.forgotPassword {
    color:rgb(89, 89, 89);
    text-decoration: underline;
    cursor:pointer;
    font-size:14px;
}
/* 
.passwordSecureTippy {
    padding:10px;
    background-color: #101010;
    border-radius: 12px;
    box-shadow: 0px 0px 10px 2px black;
} */

.passwordSecureTippy .error {
    /* color:lightgray; */
}