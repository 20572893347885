.adminDash .panel {
    height:100vh;
    width:100vw;
    background-color:white;
    position:absolute;
    left:100vw;
    transition:200ms all ease-in-out;
}

.adminDash .panel.active {
    left:0vw;
}

.featuredNFT {
    border-radius:12px;
    box-shadow:0px 0px 10px;
}

.adminDash .contractAddress input {
    border-radius:12px;
    box-shadow:0px 0px 10px;
    font-size:20px;
    min-width:200px;
}