@media(hover: hover) and (pointer: fine) {
    .maincontainer a:hover {
        color: #00ffcd !important;
    }
    .buybutton:hover {
        transform: scale(1.1) !important;
    }
    .ownercontrols:hover:disabled {
        background-color: lightgrey;
        cursor: default;
        transform: scale(1) !important;
    }
    .nftliveheader:hover {
        transform: scale(1.1);
    }
    .assetinfolikeicon:hover {
        color: #00ffcd;
    }
    .collablink:hover span {
        color: #02bdaa !important;
    }
    .unlockAudiosBttn:hover {
        transform: scale(1.1);
    }
    .unlockAudiosBttn:disabled:hover {
        transform: scale(0.9);
    }
    .headerbutton:hover {
        color: #00ffcd;
        transform: scale(1.1);
        cursor: pointer;
    }
    .sharelist li div:hover {
        box-shadow: 0px 0px 5px black;
        transform: scale(1.01);
    }
    .switchMATICbttn:hover {
        transform:scale(1.08);
    }
    .switchETHbttn:hover {
        transform: scale(1.08);
    }
}

@media(hover:none) and (any-pointer: coarse) {
    a {
        transition: 200ms 0.1s;
    }
    a:active {
        color: #00ffcd !important;
        transition: 100ms;
    }
    .buybutton {
        transition: 200ms 0.1s;
    }
    .buybutton:active {
        transform: scale(1.1) !important;
        transition: 100ms;
    }
    .ownercontrols {
        transition: 200ms 0.1s;
    }
    .ownercontrols:active:disabled {
        background-color: lightgrey;
        cursor: default;
        transform: scale(1) !important;
        transition: 100ms;
    }
    .nftliveheader {
        transition: 200ms 0.1s;
    }
    .nftliveheader:active {
        transform: scale(1.1);
        transition: 100ms;
    }
    .assetinfolikeicon {
        transition: 200ms 0.1s;
    }
    .assetinfolikeicon:active {
        color: #00ffcd;
        transition: 100ms;
    }
    .collablink span {
        transition: 200ms 0.1s;
    }
    .collablink:active span {
        color: #02bdaa !important;
        transition: 100ms;
    }
    .unlockAudiosBttn {
        transition: 200ms 0.1s;
    }
    .unlockAudiosBttn:active {
        transform: scale(1.1);
        transition: 100ms;
    }
    .unlockAudiosBttn:disabled:active {
        transform: scale(0.9);
        transition: 100ms;
    }
    .headerbutton {
        transition: 200ms 0.1s;
    }
    .headerbutton:active {
        color: #00ffcd;
        transform: scale(1.1);
        cursor: pointer;
        transition: 100ms;
    }
    .sharelist li div {
        transition: 200ms 0.1s;
    }
    .sharelist li div:active {
        box-shadow: 0px 0px 5px black;
        transform: scale(1.01);
        transition: 100ms;
    }
    .switchMATICbttn {
        transition:200ms 0.1s;
    }
    .switchMATICbttn:active {
        transform:scale(1.05);
        transition:100ms;
    }
    .switchETHbttn {
        transition: 200ms 0.1s;
    }
    .switchETHbttn:active {
        transform: scale(1.05);
        transition:100ms;
    }
}

@media only screen and (max-width: 660px) {

    body .assetwrapper {
        flex-direction: column;
    }

    body .assetsummary {
        max-width: 100%;
        width: 100%;
    }

    body .assetinfomain {
        margin-left:0px;
    }

    body .assetinfoheader {
        margin: 20px 0px 15px;
    }

    body .assetframe {
        margin: 0px;
    }

    body .audiodisplayname {
        font-size: 4vw;
    }

    body .audioplayer {
        width: 60vw;
    }

    body .descriptioncontainer {
        margin: 0;
        margin-top: 20px;
        margin-bottom:20px;
    }

    body .externallinkcontainer {
        margin-left: 0;
    }

    body .itemcounts {
        display: block;
        margin:0px;
        margin-bottom:20px;
    }

    body .viewscontainer {
        margin-right: 10px;
        display: inline-flex;
    }

    body .salecountdownblock {
        margin-bottom: 20px;
    }

    body .assetinfomain {
        /*margin-left:0px*/
    }

    body .itemframe {
        margin-right: 0px;
        margin-bottom:20px !important;
        margin:0px;
    }

    body .itemactivityframe {
        margin:0px;
        margin-top:20px;
    }

    body .timerblock {
        margin-right: 7vw;
    }

    body .pricemain {
        padding: 0px;
        /*margin-top:20px;*/
    }

    body .buyflexcontainer {
        width: 100%;
    }

    body #footer-widgets .col {
        margin-bottom: 0px;
    }

    body .responseivebr {
        display: inline;
    }

    body .assetdetailsframe {
        margin-top: 20px;
    }

    body .recommendedcont div > article {
        margin-left: 0px;
    }

}

@media only screen and (max-width: 1024px) {
}

body {
    background:#1a1a1a;
}

#main {
    position: static;
}
#footer-widgets {
    padding: 0 0 10px 0;
}
#footer-bottom, #footer-bottom p {
    color: #dddddd;
}


div {
    display: block;
}

*, ::before ::after {
    box-sizing: border-box;
}

.maincontainer p {
    margin: 0;
}

.maincontainer input[type="text"], input[type="number"], textarea {
    font-size:16px !important;
}

.materialicons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizelegibility;
    cursor: pointer;
    font-feature-settings: "liga" !important;
    font-size: 20px !important;
    color: white;
    box-sizing: border-box;
}

.maincontainer {
    background-color: #1a1a1a;
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    min-height:100vh;
}

.assetcontainer {
    padding-left: 0px;
    padding-right: 0px;
    width: 1280px;
    max-width: 100%;
    padding: 8px 8px 16px;
    box-sizing: border-box;
    display: block;
}

.margincontainer {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    display: block;
}

.assetlarge {
    box-sizing: border-box;
    display: block;
}

.assetwrapper {
    flex-direction: row;
    display: flex;
    box-sizing: border-box;
}

.assetsummary {
    flex: 3 0 0%;
    max-width: 43%;
    width: 0px;
    box-sizing: border-box;
    display: block;
    align-self: center;
}

.assetframe {
    margin: 20px;
    border-radius: 15px;
    border: 0px solid white;
    overflow: hidden;
    box-sizing: border-box;
    display: block;
    background: transparent;
    -webkit-box-shadow: 0px 0px 100px white;
    box-shadow: 0px 0px 100px white;
    /*
    -webkit-filter: drop-shadow(0px 0px 100px white);
    filter: drop-shadow(0px 0px 100px white);
        */
}

.assetheader {
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    font-weight: 500;
    padding: 12px;
    height: 42px;
    width: 100%;
    background: black;
    -webkit-box-pack: justify;
    justify-content: space-between;
    box-sizing: border-box;
}

.flexcontainer {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    box-sizing: border-box;
}

.headercontent {
    display: none;
    margin-right: 8px;
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
}

.buynowtext {
    font-weight: 600;
    font-size: 14px;
    color: blue;
    box-sizing: border-box;
}

.flexannotation {
    display: flex;
    box-sizing: border-box;
}

.assetinfolikescontainer {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    box-sizing: border-box;
}

.likesamount {
    color: white;
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
    margin-left: 5px;
    box-sizing: border-box;
}

.imageframe {
    cursor: pointer;
    max-height: 1000px;
    width: 100%;
    min-height: 200px;
    border-radius: inherit;
    height: 100%;
    box-sizing: border-box;
    display: block;
}

.imageflex {
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
    min-height: inherit;
    width: 100%;
    border-radius: inherit;
    box-sizing: border-box;
    background-color: white;
    /*aspect-ratio: 1/1;*/
}

.imagecontainer {
    height: 100%;
    width: 600px;
    border-radius: inherit;
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    max-height: 100%;
    max-width: 100%;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
}

.mainassetimage {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    /*max-height:505px*/
    border-radius: initial;
    opacity: 1;
    object-fit: contain;
    background-color: white;
    transition: opacity 400ms ease 0s;
    box-sizing: border-box;
}

.assetinfomain {
    flex: 4 0 0%;
    /*margin-left: -20px;*/
    box-sizing: border-box;
    display: block;
}

.assetinfoheader {
    margin: 20px 20px 15px;
    flex-flow: column wrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
    box-sizing: border-box;
}

.assetinfotitlecategory {
    display: flex;
    flex-direction: row;
    align-items: center;
    -webkit-box-align: center;
}

.assetinfotitlecontainer {
    display: flex;
    align-items: center;
    /*max-width:70%;*/
}

.assetinfotitle {
    color: white;
    font-size: 34px;
    font-weight: 600;
    max-width: 100%;
    margin: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: normal;
    box-sizing: border-box;
    display: block;
}

.assetcategoryname {
    width: 100%;
}

.maincontainer .assetcategorybutton {
    color: white;
    width: 100%;
    display:block;
    font-size:18px;
    margin-right:4px;
}

.assetinfocategorycontainer {
    font-weight: 500;
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    color: white;
    width: fit-content;
    max-width: 100%;
}

.assetinfocategoryimgcontainer {
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
    width: 22px;
}

.assetinfocategoryimg {
    object-fit: contain;
    width: 22px;
    height: 22px !important;
}

.artistnamecontainer {
    color: #bab8b7;
    font-size: 20px;
    font-weight: 600;
    margin-block-end: 0.83 em;
}

.itemcounts {
    margin: 20px;
    margin-top: 0px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: white;
    box-sizing: border-box;
}

.ownernamecontainer {
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
    margin-right: 20px;
    box-sizing: border-box;
}

.ownernameflex {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    height: 24px;
    width: 100%;
    box-sizing: border-box;
}

.ownernamelink {
    color: white;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-decoration: none;
    font-weight: inherit;
    box-sizing: border-box;
    cursor: pointer;
}

.ownernametext {
    box-sizing: border-box;
}

.viewscontainer {
    margin-top: 8px;
    margin-bottom: 8px;
    margin-right: 20px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    box-sizing: border-box;
}

.iconflex {
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
    margin-right: 8px;
    box-sizing: border-box;
}

.viewlikesbutton {
    margin-right: 20px;
}

.itemframe {
    margin: 20px;
    box-sizing: border-box;
    display: block;
    filter: drop-shadow(0px 0px 15px black);
}

.saleinfoframe {
    border-radius: 10px;
    border: 1px solid white;
    overflow: hidden;
    box-sizing: border-box;
    display: block;
}

.saleendingcontainer {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom: 1px solid white;
    color: white;
    font-weight: initial;
    padding: 20px;
    background-color: #141414;
    box-sizing: border-box;
    display: block;
}

.saleendstexttflex {
    -webkit-box-pack: justify;
    justify-content: space-between;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    box-sizing: border-box;
}

.flexblock {
    display: flex;
    box-sizing: border-box;
}

.inlinecontainer {
    display: inline;
    box-sizing: border-box;
}

.nomarginblock {
    margin: 0px;
    box-sizing: border-box;
}

.salecountdownblock {
    margin-top: 8px;
    box-sizing: border-box;
    display: block;
    margin-bottom:20px;
}

.salecountdowncontainer {
    font-size: 20px;
    font-weight: 500;
    box-sizing: border-box;
    display: block;
}

.salecountdownflex {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
}

.timerblock {
    margin-right: 50px;
    box-sizing: border-box;
    display: block;
}

.timernumbers {
    color: white;
    box-sizing: border-box;
    display: block;
}

.timertext {
    font-weight: 400;
    font-size: 15px;
    color: white;
    margin: 0px;
    box-sizing: border-box;
    display: block;
    margin-block-start: 0em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.pricemain {
    padding: 20px;
    background-color: #141414;
    box-sizing: border-box;
    display: block;
}

.currentpricelabel {
    color: white;
    box-sizing: border-box;
    display: block;
}

    .currentpricelabel a {
        color: #00ffcd;
    }

.assetinfopricecontainer {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 18px;
    flex-direction: row !important;
    align-items: center !important;
    box-sizing: border-box;
}

.priceflex {
    font-size: 30px;
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    font-weight: 600;
    color: white;
    width: fit-content;
    max-width: 100%;
    box-sizing: border-box;
}

.mainethicon {
    cursor: pointer;
    box-sizing: border-box;
    display: block;
}

.styledlink {
    color: white;
    text-decoration: none;
    box-sizing: border-box;
    cursor: pointer;
}

.mainethimgcontainer {
    overflow: hidden;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
    width: 24px;
    height: 24px;
    box-sizing: border-box;
}

.mainethimg {
    object-fit: contain;
    width: 24px;
    height: 24px !important;
    box-sizing: border-box;
}

.priceoverflowcontainer {
    margin-left: 0.3em;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    box-sizing: border-box;
    display: block;
}

.pricesymbol {
    font-weight: normal;
    box-sizing: border-box;
}

.fiatpricecontainer {
    font-size: 15px;
    margin-left: 7px;
    color: white;
    width: fit-content;
    max-width: 100%;
    box-sizing: border-box;
    display: block;
}

.fiatpricetext {
    color: white;
    font-weight: normal;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    box-sizing: border-box;
    display: block;
}

.buycontainer {
    display: flex;
    max-width: 420px;
    box-sizing: border-box;
}

.buyflexcontainer button:nth-child(odd) {
    margin-right:12px;
    min-width:145px;
}

.contentsblock {
    width: 100%;
    display: contents;
    box-sizing: border-box;
}

.buyflexcontainer {
    display: inline-flex;
    width: 50%;
    box-sizing: border-box;
}

body .buybutton {
    display: inline-flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 10px;
    -webkit-box-pack: center;
    justify-content: center;
    font-size: 16px !important;
    font-weight: 600 !important;
    padding: 12px 20px;
    background-color: white;
    border: 1px solid white;
    color: black;
    width: 100%;
    font: inherit;
    margin: 0px;
    box-sizing: border-box;
    appearance: auto;
    -webkit-writing-mode: horizontal-tb !important;
    cursor: pointer;
    transition: all 200ms ease-in-out;
}

    

    .buybutton:disabled {
        background-color: lightgrey;
        font-size: 14px !important;
        cursor: wait;
    }

.ownercontrols {
    border: 1px solid rgb(229, 232, 235);
    border-radius: 0px;
}

    .ownercontrols:not(:first-child) {
        border-left: none;
    }

    .ownercontrols:first-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }

    .ownercontrols:last-child {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        border: 1px solid rgb(229, 232, 235);
    }

    

#ownedbutton:disabled {
    background-color: lightgrey;
    font-size: 14px !important;
    cursor: default;
    border: 0px solid white;
}

#cancelbttn:disabled {
    cursor:wait !important;
}

.buyiconcontainer {
    display: flex;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 12px;
    box-sizing: border-box;
}

.buyicon {
    color: black;
    cursor: inherit !important;
}

.itemactivityframe {
    margin-top: 0px;
    margin: 20px;
    box-sizing: border-box;
    display: block;
    filter: drop-shadow(0px 0px 15px black);
}

.itemactivitycontainer {
    overflow: auto;
    box-sizing: border-box;
    display: block;
}

.panelcontainer {
    box-sizing: border-box;
    display: block;
}

.basepanelcontainer {
    border-radius: 10px;
    border-right: 1px solid white;
    border-bottom: 1px solid white;
    border-left: 1px solid white;
    border-image: initial;
    border-top: none;
    color: white;
    background-color: #141414;
    overflow: hidden;
    box-sizing: border-box;
    display: block;
}

.basepanelheader {
    -webkit-box-align: center;
    align-items: center;
    border-radius: 10px;
    border-top: 1px solid white;
    cursor: pointer;
    display: flex;
    font-size: 16px;
    font-weight: 600;
    padding: 20px;
    user-select: none;
    background-color: #141414;
    box-sizing: border-box;
}

.panelicon {
    margin-right: 10px;
}

.togglepanel {
    margin-left: auto;
    color: white;
}

.basepanelbody {
    border-top: 1 px solid white;
    color: white;
    background-color: #141414;
}

.panelcontentcontainer {
    height: initial;
    overflow: visible;
    transition: height 100ms ease 0s;
}

.assethistorypanel {
    overflow-x: auto;
}

.assethistoryfilters {
    border-bottom: 1px solid lightgrey;
    /* padding: 16px; */
}

.scrollboxcontainer {
    max-height: 332px;
    overflow-x: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.scrollboxcontent {
    border-color: rgba(0,0,0,0);
    overflow-y: auto;
    transition: border-color 0.2s ease-in 0s;
}

.rowheadercontainer {
    position: sticky;
    top: 0px;
    z-index: 1;
    display: flex;
}

.rowheadercontainer .rowcell {
    border-bottom: 1px solid white;
}

.rowcell {
    -webkit-box-align: center;
    align-items: center;
    border-bottom: 2px solid rgba(255, 255, 255, 0.4);
    display: flex;
    flex: 1 0 100px;
    overflow-x: auto;
    padding: 16px 4px;
    background-color: #141414;
    color: white;
    padding-bottom: 4px;
    padding-top: 4px;
}

.spacedcellevent {
    background-color: #141414;
    color: white;
    padding-bottom: 4px;
    padding-top: 4px;
    padding-left: 16px;
    flex-basis: 130px;
}

.spacedcellprice {
    background-color: #141414;
    color: white;
    padding-bottom: 4px;
    padding-top: 4px;
    flex-basis: 100px;
}

.cellspaced {
    flex-basis: 100px;
}

.maincontainer .displayflex {
    display: flex;
}

.eventcellicon {
    vertical-align: middle;
    margin-right: 8px;
    margin-bottom: 4px;
}

.rowpricecontainer {
    font-weight: 400;
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    color: white;
    width: fit-content;
    max-width: 100%;
}

.rowethimgcontainer {
    overflow: hidden;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
    width: 16px;
    height: 16px;
    box-sizing: border-box;
}

.rowethimg {
    object-fit: contain;
    width: 16px;
    height: 16px !important;
    box-sizing: border-box;
}

.rowpriceamount {
    margin-left: 0.3em;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.ellipsisoverflow {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.rowcell:last-child {
    padding-right: 16px;
}

.rowcell a {
    align-items: center;
    display: flex;
}

.rowcell .ownernameflex a {
    display:block;
}

/*
.popup .popupoverlay {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.7);
    z-index: 10;
    display: none;
    transition: all 300ms ease-in-out;
}

.popup .popupcontent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    background: #fff;
    border-radius: 10px;
    border: 1px solid rgba(0,0,0,0.5);
    width: 700px;
    max-width: 80%;
    z-index: 11;
    padding: 20px;
    box-sizing: border-box;
    transition: all 300ms ease-in-out;
}

.popup .popupclose {
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;
    width: 30px;
    height: 30px;
    background: #222;
    color: #fff;
    font-size: 25px;
    font-weight: 600;
    line-height: 30px;
    text-align: center;
    border-radius: 50%;
}

.popup.active .popupoverlay {
    display: block;
}

.popup.active .popupcontent {
    transition: all 300ms ease-in-out;
    transform: translate(-50%, -50%) scale(1);
}
    */
.assetdetailsframe {
    margin: 20px;
    border-radius: 10px;
    border: 1px solid white;
    overflow: hidden;
    filter: drop-shadow(0px 0px 15px black);
}

.audiobasepanelcontainer {
    border-top: none;
    border-left: none;
    border-right: none;
    margin-bottom: -1px;
    margin-top: 1px;
    border-radius: 0px;
    color: white;
}

.maincontainer .audiobasepanelheader {
    cursor: initial;
    border: 0px;
    margin: 0px;
    border-radius: 0px;
    width: 100%;
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    font-size: 16px;
    font-weight: 600;
    padding: 20px;
    user-select: none;
    background-color: #141414;
    font-family: inherit;
    appearance: button;
    border-bottom: 1px solid black;
}

.audiobasepanelbody {
    border-left: 0px;
    border-right: 0px;
    border-radius: 0px;
}

.audiopanelcontentcontainer {
    height: initial;
    max-height: 200px;
    overflow: auto;
    transition: height 100ms ease 0s;
}

.audiopanelpaddedcontent {
    padding: 30px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.audioplayer {
    margin-bottom: 15px;
}

.audiodisplayname {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 8px !important;
}

.descriptioncontainer {
    color: white;
    margin: 20px;
    margin-bottom: 20px;
    font-weight: 500;
}

.description {
    color: white;
}


.externallinkcontainer {
    margin-left: 20px;
}

    .externallinkcontainer a {
        color: lightgray;
        font-weight: 400;
    }

    .externallinkcontainer a p {
        display:inline-flex;
    }

.noticecontainer {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom: 1px solid white;
    color: white;
    font-weight: initial;
    padding: 20px;
    background-color: #141414;
    display: block;
}

.mainflex {
    /*transform: scale(0);*/
    padding: 5px;
    margin-top: 21px;
    /*opacity: 0;*/
    transition: all 1.5s;
}

    .mainflex.fadein {
        transform: scale(1);
        opacity: 1;
    }

.numberbox {
    max-width: 150px !important;
}

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.previousbidcontainer {
    padding: 10px;
    text-align: center;
}

.yourbidcontainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
}

.nftliveheader {
    color: #00ffcd;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    background-color: #364B44;
    padding: 10px;
    filter: drop-shadow(0px 5px 6px black);
    border-radius: 10px;
    transition: all 200ms ease-in-out;
}

.inlineicon {
    margin-left: 5px;
    display: inline-flex;
    vertical-align: text-bottom;
}

.responseivebr {
    display: none;
}

.tooltip-inner {
    font-size: 13px;
}

.assetinfolikeicon {
    color:white;
    cursor:pointer;
    transition:all 200ms ease-in-out;
}

.listform {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
}

.listform .invalid-feedback {
    text-align: center;
}

.listform .error {
    color: #dc3545;
}

.popup .submitbttn {
    font-weight: 500 !important;
    color: black !important;
    background-color: #00ffcd !important;
    text-align: center;
    display:inline-block;
    transition: all 200ms ease-in-out;
}

.submitbttn:disabled {
    background-color: #02b5a3 !important;
    transform: scale(0.9);
}

.inputethicon {
    width:20px;
    margin-left:8px;
}

.auctionform {
    display: flex;
    flex-directioN: column;
    align-items: center;
}

.auctionform > * {
    margin: 10px;
}

.auctionnobids {
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: 400;
}

.daterangepicker .drp-calendar {
    max-width:100% !important;
}

.collaboratorstext {
    color:white;
    font-weight:500;
}

.collaborator {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 6px;
}

.collaborator span {
    color: #00ffcd;
    transition: all 200ms ease-in-out;
}

.collablink:last-child span:last-child {
    display:none;
}

.collabverificationbadge {
    width: 18px;
    margin-left: 2px;
}

.collaboratorseperator {
    font-weight:500;
    color:#00ffcd;
}

.maincontainer .unlockAudiosBttn {
    padding: 16px;
    width: 100%;
    display: flex;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 10px black;
    cursor:pointer;
    transition:all 200ms ease-in-out;
}

.unlockAudiosBttn span {
    font-size: 16px;
    font-weight: 600;
}

.unlockAudiosBttn i {
    margin-left: 3px;
    font-size: 25px;
}

.unlockAudiosBttn img {
    width: 30px;
    margin-left: 3px;
}

.recommendedcont {
    transform:scale(0.9);
}

.recommendednfts {
    flex-wrap:wrap;
}

.recommendedcont div > article {
    margin-left: 25px;
}

.creatorverified {
    
}

.ownerverified {
    
}

.headerbuttonsgrid {
    max-width: 95vw;
}

.headerbuttonsflex {
    display: flex;
    justify-content: flex-end;
    max-height: 100%;
    margin-top:15px;
}

.headerbuttonscontainer {
    width: fit-content;
    display: flex;
    z-index: 100;
}

.headerbutton {
    display: inline-flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    /*border-radius: 10px;*/
    -webkit-box-pack: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    padding: 12px;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(229, 232, 235);
    color: rgb(112, 122, 131);
    transition: all 200ms ease-in-out;
}

    .headerbutton:not(:first-child) {
        border-left: none;
    }

    .headerbutton:first-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }

    .headerbutton:last-child {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }

.sharedropdown {
}

.sharelist {
    margin: 0 !important;
    padding: 0px !important;
    background:white;
    border-radius:12px;
}

    .sharelist li div {
        display: flex;
        align-items: center;
        flex-direction: row;
        background: white;
        color: black;
        font-size: 20px;
        font-weight: 500;
        padding: 15px;
        cursor: pointer;
        transition: all 200ms ease-in-out;
    }

    .sharelist li:first-child div {
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
    }

    .sharelist li:last-child div {
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
    }

    .sharelist li:nth-child(2) {
        border-bottom: 1px solid lightgrey;
        border-top: 1px solid lightgrey;
        border-radius: 0px;
    }

.shareitem img {
    width: 30px;
    height: 30px;
    margin-right: 8px;
}

.activityname {
    text-overflow: ellipsis;
    overflow: hidden;
}

.activityname:hover {
    color: #00ffcd;
}

.activityverified {
    width: 17px;
    margin-left: 3px;
}

.assetinfofooter {
    position:relative;
    bottom:0;
}

body .switchMATICbttn {
    background: #8247e5;
    color: white;
    padding: 16px;
    font-size: 18px;
    line-height: 18px;
    border-radius: 10px;
    box-shadow: 0px 0px 2px black;
    margin-bottom: 30px;
    transition: all 200ms ease-in-out;
}

.switchETHbttn {
    background: #00ffcd;
    color: black;
    padding: 16px;
    font-size: 18px;
    line-height: 18px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px black;
    margin-bottom: 30px;
    transition: all 200ms ease-in-out;
}

/*
.daterangepicker {
    top: 20% !important;
    position: fixed !important;
}
*/

.maincontainer .creatorname {
    color: #bab8b7;
}

.pricemain {
    padding:0px;
}

.eventLink {
    color:white;
}

.controlBtnInfo {
    margin-top: 18px;
    color: lightgray;
}

.noOffers {
    color:white;
    display:flex;
    justify-content: center;
    align-items: center;
    padding:20px;
    font-size:25px;
}

.giftCheckboxContainer {
    display:flex;
    flex-direction: row;
    align-items: center;
    gap:10px;
}

.giftCheckboxText {
    display:flex;
    flex-direction: row;
    align-items: center;
    gap:5px;
}

.giftSwitch {
    position:relative;
    display:inline-block;
    width:50px;
    height:24px;
}

.giftSwitch input {
    display:none;
}

.slider {
    position:absolute;
    cursor:pointer;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background-color:#1a1a1a;
    -webkit-transition:.4s;
    transition:.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: #00ffcd;
}

input:focus + .slider {
    box-shadow: 0 0 5px black;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

.slider.round {
    border-radius: 34px;
}
  
.slider.round:before {
    border-radius: 50%;
}

.maincontainer .nftProcessingHeader {
    font-size:20px;
    margin-bottom: 10px !important;
}