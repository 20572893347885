.psMain {
    background:#1a1a1a;
}

.psHeader {
    color:white;
    font-size:32px;
    font-weight:600;
    margin:0;
    margin-left:10px;
}

.psBack {
    font-size:40px !important;
    padding:10px;
    cursor:pointer;
}
    
.backarrow {
    color:white;
    transition:all 200ms ease-in-out;
}

.backarrow:hover {
    color:#00ffcd;
}

.psMain .required {
    color:red;
    display:inline;
}

@media only screen and (max-width:580px) {
    .ppinputcont {
        justify-content:center !important;
    }
}

.psMain h3 {
    color: white;
    font-size: 20px;
}

.psMain p {
    color: white;
}

.psMain .info {
    color: lightgray;
    font-size: 13px;
    margin-top: 1px;
    margin-bottom: 18px;
}

.psMain form input[type=file] {
    display: none;
    display: absolute;
}

.psMain form input[type=text] {
    display: inline-block;
    min-height: 40px;
    line-height: 1.8;
    padding: 6px 12px;
    vertical-align: middle;
    background-color: transparent;
    color: #333;
    border: 1px solid #ddd;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.psMain form input[type=email] {
    background: white !important;
    width: 100% !important;
    display: inline-block;
    min-height: 40px;
    line-height: 1.8;
    padding: 6px 12px;
    vertical-align: middle;
    background-color: transparent;
    color: #333;
    border: 1px solid #ddd;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.psMain form input[type=email]:disabled {
    color:rgb(109, 109, 109) !important;
}

.psMain form textarea {
    background: white !important;
    width: 100% !important;
    border-radius:3px;
    min-height: 150px;
    line-height: 1.5;
    resize: vertical;
}

.psMain form input[type=submit] {
    background: #00ffcc;
    border-radius: 15px;
    color: black;
    font-weight: 600;
    letter-spacing: 1px;
    font-size: 20px;
    width:100%;
    margin: 0;
    padding: 14px 20px;
    border: 0;
    transition:all 200ms ease-in-out;
}

.psMain input[type=submit]:hover {
    color: white;
    background: #00ffcd;
    transform: scale(1.2);
    box-shadow: 0px 0px 10px 4px white;
}

.displayNameInput {
    background: white !important;
    width: 100% !important;
    max-width: 300px;
}

.imagediv {
    position: relative;
    z-index: 50;
    display: block;
    width:104px;
    height:104px;
    display:inline-flex;
}

.imageinputcontainer {
    padding: 52px; /*4*/
    cursor: pointer;
    position: absolute;
    border: none;
    border-radius: 50%;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
    z-index: 2;
    color: white;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.15);
    transition: all 200ms ease-in-out;
}

.imageinputcontainer:hover {
    opacity: 1;
} 

.imageinputoverlay {
    position: absolute;
    z-index: 70;
    height: 0px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    display: block;
}

.imageinputoverlayflex {
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
    height: 100%;
}

.profileimgcontainer {
    display: inline-block;
    height: 100%;
    width: 100%;
    cursor: pointer;
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    max-height: 100%;
    max-width: 100%;
    overflow: hidden;
    position: relative;
    border-radius: 50%;
    /*border: 2px double white;*/
    border: double 4px transparent;
    border-radius: 80px;
    background-image: linear-gradient(#1a1a1a, #1a1a1a), radial-gradient(circle at top left, #FFFFFF,#FFFFFF);
    background-origin: border-box;
    background-clip: content-box, border-box;
}

.profilepic {
    object-fit:cover;
    height:100% !important;
    width:100%;
    border-radius: 50% !important;
}

.colorinputcontainer {
    margin-left: 40px;
    margin-top: 30px;
}

.colorinput {
    width: 40px !important;
    height: 40px;
    display: inline-flex !important;
    justify-content: center;
    padding: 1px 2px !important;
    border-radius: 5px !important;
    border: 2px solid #ddd !important;
    cursor: pointer;
}

.error {
    margin-top: 10px;
    font-size: 16px;
    color: rgb(255, 50, 50);
}

.psMain form input[type=email], .psMain form input[type=text], .psMain form textarea {
    box-shadow: 0px 5px 10px black;
    font-size: 16px !important;
    border:none;
    outline:none;
}

.profilesettingsform {
    padding:10px;
    max-width: 720px;
}