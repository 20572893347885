@media only screen and (max-width:600px) {
    .mainhelp h3 {
        margin-left: 0px !important;
    }

    .faqheader {
        text-align: center !important;
    }

    .qacontainer {
        text-align: center;
    }
}

.helpcontact {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
}

.helpcontact a {
    color:#00ffcd;
}


/*.helplink a {
    color: #00ffcd !important;
}*/

a {
    color:#00ffcd;
}

a:hover {
    color:aquamarine;
    transition:100ms;
}

.mainhelp {
    /* background: rgb(0,112,255);
    background: linear-gradient(56deg, rgba(0,112,255,1) 0%, rgba(0,255,205,1) 93%); */
    background:#1a1a1a;
    min-height: 100vh;
}

    .mainhelp h3 {
        text-align: center;
        color: lightgray;
        font-weight: 500;
        filter: drop-shadow(0px 5px 1px black);
        font-size: 45px;
        padding: 10px;
        border-radius: 20px;
    }

.mainhelp .email {
    font-size: clamp(14px, 9vw, 45px);
}

.faqheader {
    margin-left: 30px;
    text-align: left;
    margin-bottom: 30px;
}

.qacontainer {
    margin-left: 40px;
}

.qa {
    margin-bottom: 35px;
    max-width: 90%;
}

.question {
    color: black;
    /* background: rgb(0,112,255);
    background: linear-gradient(56deg, rgba(0,112,255,1) 0%, rgba(0,180,231,1) 0%, rgba(0,255,205,1) 50%); */
    font-size: 24px;
    margin-bottom: 10px;
    font-weight: 500;
    padding: 10px;
    border-radius: 10px;
    border: 2px black solid;
    transition: all 200ms ease-in-out;
}

    .question:hover {
        transform: scale(1.05);
    }

.answer {
    margin-top:5px;
    background: #191919;
    background: rgb(25,25,25);
    background: linear-gradient(90deg, rgba(25,25,25,1) 0%, rgba(40,39,39,1) 100%); 
    padding: 10px;
    border-radius: 10px;
    /* border: 2px darkgray solid; */
    box-shadow: 0px 0px 5px -1px white;
    font-size: 25px;
    transition: all 200ms ease-in-out;
    color: lightgray;
    font-weight: 400;
    font-family: roboto;
}

.answer a {
    color:#00ffcd;
}

/* Forgot Password */
.forgotPasswordMain {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height:100vh;
    width: 100%;
    text-align: center;
    --ReactInputVerificationCode-itemWidth: 2.5rem;
    --ReactInputVerificationCode-itemHeight: 3.5rem;
    --ReactInputVerificationCode-itemSpacing: 0.7rem;
}

.forgotPasswordMain .header {
    display:flex;
    flex-direction: column;
    align-items: center;
    padding:15px;
}

.forgotPasswordMain h2 {
    color:lightgray;
}

.forgotPasswordMain h4 {
    color:darkgray;
    font-size:18px;
}

.forgotPasswordMain .ReactInputVerificationCode__item {
    background-color: white;
    border:none;
    outline:none;
    font-weight: 800;
    color:black;
    border-radius: 12px;
    box-shadow: none;

}

.forgotPasswordMain .ReactInputVerificationCode__item.is-active {
    background-color: #60ffdf;
    color:black;
    box-shadow: none;
}

.forgotPasswordMain .error {
    padding:15px;
}