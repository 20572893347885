@media only screen and (max-width:660px) {
    .iconsList {
        display:inline-grid;
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media (hover: hover) and (pointer: fine)  {
    .contactMaster .email:hover {
        color:#1a1a1a;
    }
}

.contactMaster {
    background-color: #00ffcd;
    height:100vh;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding:20px;
}

.contactMaster .email {
    font-family: 'Open Sans Semibold';
    font-weight:600;
    font-size: clamp(14px, 8vw, 45px);
    color:black;
    margin-bottom:10px;
}

.contactIcon {
    font-size:40px;
    padding:10px;
    background-color:#1a1a1a;
    color:white;
    border-radius: 12px;
    margin:10px;
    text-align: center;
    transition: 200ms all ease-in-out;
}

.contactIcon:hover {
    color:white;
}

.contactIcon i {
    width:52px;
    height:52px;
}

.contactIcon:hover {
    background-color: #333333;
}